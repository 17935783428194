import { StripeProductType } from '../../types/Enum/StripeProductType';
import { AbbyPlans, PlansConfig } from '../../types/Enum/Plans';

export function getFeesAmount(amount: number, plan: AbbyPlans) {
  // exprimé en centimes
  const fixedCostStripe = 25;
  // doit être converti en pourcentage
  const feesInPourcentageStripe = 1.40;

  const { stripe } = PlansConfig[plan || AbbyPlans.ABBY_FREE];

  // exprimé en centimes
  const fixedCostPlatform = stripe.flat;
  // doit être converti en pourcentage
  const feesInPourcentagePlatform = stripe.rate;

  // les frais imposés de base par stripe
  const stripeFees = amount * (feesInPourcentageStripe / 100) + fixedCostStripe;
  // les frais que l'on prélève en tant que plateforme
  const platformFees = amount * (feesInPourcentagePlatform / 100) + fixedCostPlatform;
  return Math.round(stripeFees + platformFees);
}

const recurringPlans = [
  StripeProductType.ABBY_START,
  StripeProductType.ABBY_PRO,
  StripeProductType.ABBY_CREATION_START,
  StripeProductType.ABBY_CREATION_START_PREMIUM,
  StripeProductType.ABBY_CE,
];

export function isProductRecurring(product: StripeProductType) {
  return recurringPlans.includes(product);
}
