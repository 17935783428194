import type { TrackAdvertising } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Advertising extends Resource {
  track (payload: TrackAdvertising): Promise<void> {
    return this.axios.$post('/advertising/track', payload)
  }

  identify (): Promise<void> {
    return this.axios.$post('/advertising/identify')
  }

  trackV2 (payload: any): Promise<void> {
    return this.axios.$post('/v2/analytic/', payload, { withCredentials: true })
  }

  trackUnauthenticatedV2 (payload: any): Promise<void> {
    return this.axios.$post('/v2/analytic/trackUnauthenticated', payload, { withCredentials: true })
  }

  trackRelogged (): Promise<void> {
    return this.axios.$post('/advertising/relogged', {}, { withCredentials: true })
  }
}
