
import { Component, Emit, ModelSync, Prop, Ref, Vue } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  name: 'as-date-picker',
  components: {
    ValidationProvider,
  },
})
export default class ASDatePicker extends Vue {
  @Prop({ default: '' })
  rules!: object | string;

  @Prop({ default: false })
  hideLabel!: boolean | string;

  @Prop({ required: false })
  tooltip!: string;

  @Prop({ default: 'primary' })
  tooltipIconColor!: string;

  @Prop({ required: false, default: false })
  range!: boolean | string;

  @Prop({ required: false, default: false })
  dense!: boolean | string;

  @Prop({ required: false, default: false })
  timePicker!: boolean | string;

  @Prop({ required: false, default: '1920-01-01' })
  min!: string | null;

  @Prop()
  activePicker!: string;

  @Ref('validator') readonly validator!: InstanceType<typeof ValidationProvider>;

  @Prop({ default: () => () => true })
  allowedDates!: () => boolean;

  @ModelSync('value', 'input')
  readonly innerValue: any;

  datePickerIsOpen = false

  tempActivePicker = 'DATE'

  setActivePicker (val: boolean) {
    val && setTimeout(() => {
      this.tempActivePicker = this.activePicker || 'DATE'
      this.validator.validate()
    }, 100)
  }

  today = this.$dayjs()

  get _hideLabel () {
    return !!this.hideLabel || typeof this.hideLabel === 'string'
  }

  get _timePicker () {
    return !!this.timePicker || typeof this.timePicker === 'string'
  }

  get _dense () {
    return !!this.dense || typeof this.dense === 'string'
  }

  get _range () {
    return !!this.range || typeof this.range === 'string'
  }

  get hasSlot () {
    return !!this.$slots.tooltip || !!this.$scopedSlots.tooltip
  }

  get formattedDate () {
    if (this._range) {
      const first = this.innerValue?.[0] ? this.$dayjs(this.innerValue[0] || this.today).format('DD/MM/YYYY') : null
      const last = this.innerValue?.[1] ? this.$dayjs(this.innerValue[1] || this.today).format('DD/MM/YYYY') : null
      if (!first && !last) {
        return null
      }
      return `${first ?? ''} - ${last ?? ''}`
    }
    if (this.innerValue) {
      return this.$dayjs(this.innerValue).format('DD/MM/YYYY')
    }
    return null
  }

  set formattedDate (value: string | null) {
    this.$emit('input', value)
  }

  dateSelected () {
    if (this._range) {
      return
    }
    this.datePickerIsOpen = false
  }

  @Emit('select')
  select () {
    this.datePickerIsOpen = false
    return this.innerValue
  }

  close () {
    this.datePickerIsOpen = false
    this.$emit('input', [])
  }

  updateTime (value: string) {
    this.$emit('input', this.$dayjs(this.innerValue).format(`YYYY-MM-DD ${value}`))
  }

  onClickOutside () {
    if (this._range && this.datePickerIsOpen) {
      this.$emit('input', [])
    }
  }
}
