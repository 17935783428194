import { Plugin } from '@nuxt/types'
import { useToasterManagerStore } from '~/logic/contexts/shared-kernel/infrastructure/store/toasterManagerPinia.store'

export type ToasterType = 'info' | 'success' | 'warning' | 'error'

export interface ToasterParams {
  title?: string
  message: string
  icon?: string,
  timeout?: number,
  dismissible?: boolean
  direction?: {
    x: 'left' | 'right'
    y: 'top' | 'bottom'
  }
  button?: {
    text: string
    direction?: 'left' | 'right'
    action: () => void
  },
}

export interface Toaster {
  title?: string
  message: string
  createdAt: number
  icon?: string
  type: ToasterType
  timeout?: number,
  exiting: boolean,
  timerId?: number,
  paused: boolean,
  dismissible: boolean
  direction: {
    x: 'left' | 'right'
    y: 'top' | 'bottom'
  }
  button?: {
    text: string
    direction?: 'left' | 'right'
    action: () => void
  },
}

export interface ToasterManagerInstance {
  error(params: ToasterParams): void;
  success(params: ToasterParams): void;
  warning(params: ToasterParams): void;
  info(params: ToasterParams): void;
}

const ToasterManagerPlugin: Plugin = (context, inject) => {
  const toasterManagerStore = useToasterManagerStore()

  const defaultConfig: { dismissible: boolean, exiting: boolean, paused: boolean, direction: { x: 'left' | 'right', y: 'top' | 'bottom' }} = {
    dismissible: true,
    exiting: false,
    paused: false,
    direction: {
      x: 'right',
      y: 'top',
    },
  }

  const calculateTimeNeededToReadMessage = (message: string) => {
    const readingTimeNeeded = message.split(' ').length * 240 + 500
    return readingTimeNeeded > 5000 ? readingTimeNeeded : 5000
  }

  inject('toasterManager', {
    error: (params: ToasterParams) => {
      toasterManagerStore.addToaster({
        ...defaultConfig,
        ...params,
        type: 'error',
        direction: params?.direction || defaultConfig.direction,
        dismissible: params?.dismissible || defaultConfig.dismissible,
        timeout: params?.timeout || calculateTimeNeededToReadMessage(params.message),
        createdAt: Date.now(),
      })
    },
    success: (params: ToasterParams) => {
      toasterManagerStore.addToaster({
        ...defaultConfig,
        ...params,
        type: 'success',
        direction: params?.direction || defaultConfig.direction,
        dismissible: params?.dismissible || defaultConfig.dismissible,
        timeout: params?.timeout || calculateTimeNeededToReadMessage(params.message),
        createdAt: Date.now(),
      })
    },
    warning: (params: ToasterParams) => {
      toasterManagerStore.addToaster({
        ...defaultConfig,
        ...params,
        type: 'warning',
        direction: params?.direction || defaultConfig.direction,
        dismissible: params?.dismissible || defaultConfig.dismissible,
        timeout: params?.timeout || calculateTimeNeededToReadMessage(params.message),
        createdAt: Date.now(),
      })
    },
    info: (params: ToasterParams) => {
      toasterManagerStore.addToaster({
        ...defaultConfig,
        ...params,
        type: 'info',
        direction: params?.direction || defaultConfig.direction,
        dismissible: params?.dismissible || defaultConfig.dismissible,
        timeout: params?.timeout || calculateTimeNeededToReadMessage(params.message),
        createdAt: Date.now(),
      })
    },
  })
}

declare module 'vue/types/vue' {
  interface Vue {
    $toasterManager: ToasterManagerInstance
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $toasterManager: ToasterManagerInstance
  }
  interface Context {
    $toasterManager: ToasterManagerInstance
  }
}

declare module 'vuex/types/index' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $toasterManager: ToasterManagerInstance
  }
}

export default ToasterManagerPlugin
