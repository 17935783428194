
import { Component, Getter, Prop, Vue } from 'nuxt-property-decorator'
import {
  AbbyPlans,
  ABGroupPricing,
  IBillingConfiguration,
  PersonaFeature,
  PlansConfig,
  ReadPersona,
  StripeProductFrequency,
} from '@abby/core-legacy'
import AbbyPlanChip from '~/components-legacy/chips/AbbyPlanChip.vue'
import SignupButton from '~/components-legacy/landing/SignupButton.vue'

@Component({
  components: { SignupButton, AbbyPlanChip },
})
export default class AbbyNewComparativePlan extends Vue {
  @Getter('billingConfiguration/billingConfiguration')
  billingConfiguration!: IBillingConfiguration;

  @Getter('guide/persona') persona!: ReadPersona | null;

  @Prop()
  frequency!: StripeProductFrequency;

  get taxText () {
    // expérimentation A --> après le 29/11/2023 groupe avec le prix HT
    if (this.$planManager.abGroupPricing() === ABGroupPricing.GROUP_A) {
      return 'mois ht'
    }
    // expérimentation par défault --> avant le 29/11/2023 groupe avec le prix TTC
    return 'mois ttc'
  }

  get isPromotionDay () {
    const isSubProYearly = this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_PRO && !this.$planManager.isTrial() && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR
    const isPromotionDay = this.$dayjs().isBetween('2024-01-02', '2025-03-31', 'day', '[]')

    return !isSubProYearly && isPromotionDay
  }

  get promotionRate () {
    if (this.isPromotionDay) {
      return 0.8
    } else {
      return 1
    }
  }

  get hasTiersPrestation () {
    return this.persona?.interestedInFeatures?.includes(PersonaFeature.IMMEDIATE_ADVANCE_API) || this.billingConfiguration?.tiersPrestationActivated
  }

  get AbbyPlans () {
    return AbbyPlans
  }

  get SAPProPlan () {
    return {
      title: 'Offre SAP',
      subtitle: 'Tous les outils pour facturer avec l\'avance immédiate',

    }
  }

  get plans () {
    return [{
      title: 'Basique',
      callToAction: 'Je m\'inscris',
      subtitle: 'Facturez vos clients simplement',
      condition: 'Sans engagement',
      plan: AbbyPlans.ABBY_FREE,
      free: true,
      source: 'abby-free',
      pricing: {
        [StripeProductFrequency.MONTH]: { text: 'Gratuit' },
        [StripeProductFrequency.YEAR]: { text: 'Gratuit' },
      },
    }, {
      title: 'Commencer',
      callToAction: 'Essayer gratuitement',
      subtitle: 'Mettez en valeur votre entreprise',
      condition: '14 jours offerts, sans engagement',
      plan: AbbyPlans.ABBY_START,
      source: 'abby-start',
      pricing: {
        ...PlansConfig[AbbyPlans.ABBY_START].pricing[this.$planManager.abGroupPricing()],
      },
    }, {
      title: 'Maitriser',
      callToAction: 'Essayer gratuitement',
      subtitle: 'Travaillez l’esprit libre avec un environnement tout-en-un',
      condition: '14 jours offerts, sans engagement',
      popular: true,
      plan: AbbyPlans.ABBY_PRO,
      source: 'abby-pro',
      pricing: {
        ...PlansConfig[AbbyPlans.ABBY_PRO].pricing[this.$planManager.abGroupPricing()],
      },
      ...this.hasTiersPrestation
        ? this.SAPProPlan
        : {},
    }, {
      title: 'Aller plus loin',
      callToAction: 'Essayer gratuitement',
      subtitle: 'Faites vous accompagner pour la croissance de votre entreprise',
      condition: '14 jours offerts, sans engagement',
      plan: AbbyPlans.ABBY_BUSINESS,
      soon: true,
      source: 'abby-business',
      pricing: {
        ...PlansConfig[AbbyPlans.ABBY_BUSINESS].pricing[this.$planManager.abGroupPricing()],
      },
    }]
  }

  get StripeProductFrequency () {
    return StripeProductFrequency
  }

  get comparations () {
    return [
      {
        title: 'Facturation',
        contents: [{
          title: 'Devis et facture',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Paiement en ligne avec stripe',
          type: 'text',
          values: ['3.4% + 0,60 €', '2.4% + 0,50 €', '1.5% + 0,25 €', '1.5% + 0,25 €'],
        }, {
          title: 'Personnalisation basique des documents',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Répertoire clients',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Bibliothèque d\'articles',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Factures d\'acompte',
          type: 'check',
          values: [false, true, true, true],
        }, {
          title: 'Retirer le logo Abby de vos documents',
          type: 'check',
          values: [false, true, true, true],
        }, {
          title: 'Archivage des documents',
          type: 'check',
          values: [false, true, true, true],
        }, {
          title: 'Personnalisation du titre d\'un document',
          type: 'check',
          values: [false, true, true, true],
        }, {
          title: 'Relances automatiques (impayés)',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Factures et devis à la TVA',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Signature électronique (achat en supplément disponible)',
          type: 'text',
          values: ['Non', 'Non', '2 / mois', 'Illimité'],
        }, {
          title: 'Factures récurrentes',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Avance de crédit d\'impôts (API tiers de prestation)',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Personnalisez vos e-mails',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Changer la langue de vos documents',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Ajoutez des pièces jointes à vos documents',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Téléchargez vos attestations fiscales',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Ajoutez des logos légaux de pieds de page',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Etats d’Activités Mensuels (EMA) pour la déclaration NOVA',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Factures en langues et devises étrangères',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Gestion des stocks',
          type: 'check',
          icon: {
            text: 'Bientôt',
            color: 'primary',
          },
          values: [false, false, false, true],
        }],
      },
      {
        title: 'Comptabilité',
        contents: [{
          title: 'Livre des recettes',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Livre des achats',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Estimation CA et cotisations Urssaf',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Déclaration et paiement Urssaf',
          type: 'check',
          values: [false, true, true, true],
        }, {
          title: 'Rappel Urssaf, Impôts et CFE',
          type: 'check',
          values: [false, true, true, true],
        }, {
          title: 'Connexion au compte bancaire',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Compte de résultat',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Plan comptable personnalisé',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Rapprochement bancaire automatique',
          type: 'check',
          icon: {
            text: 'Bientôt',
            color: 'primary',
          },
          values: [false, false, false, true],
        }, {
          title: 'Télé-déclaration de la TVA',
          type: 'check',
          icon: {
            text: 'Bientôt',
            color: 'primary',
          },
          values: [false, false, false, true],
        }, {
          title: 'Export FEC pour votre expert comptable',
          type: 'check',
          icon: {
            text: 'Bientôt',
            color: 'primary',
          },
          values: [false, false, false, true],
        }],
      },
      {
        title: 'Productivité',
        contents: [{
          title: 'Gestion de vos tâches',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Tableau de suivi',
          type: 'text',
          values: ['5', '10', 'Illimité', 'Illimité'],
        }, {
          title: 'Suivi du temps',
          type: 'text',
          values: ['10', '20', 'Illimité', 'Illimité'],
        }, {
          title: 'Automatisation / intégrations',
          type: 'check',
          icon: {
            color: 'black',
            text: 'Bêta',
          },
          values: [false, false, true, true],
        }, {
          title: 'Export CSV et Excel des données',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Compte de résultat',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Accès formations Académie',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Multi-utilisateurs',
          type: 'check',
          icon: {
            text: 'Bientôt',
            color: 'primary',
          },
          values: [false, false, false, true],
        }],
      },
      {
        title: 'Démarches',
        contents: [{
          title: 'Demande de l\'ACRE (aide de l\'État)',
          type: 'check',
          values: [false, true, true, true],
        }, {
          title: 'Déclaration CFE',
          type: 'check',
          values: [false, true, true, true],
        }, {
          title: 'Démarches prioritaires',
          type: 'check',
          values: [false, false, true, true],
        }],
      },
      {
        title: 'Support',
        contents: [{
          title: 'Support par ticket',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Support par chat',
          type: 'check',
          values: [true, true, true, true],
        }, {
          title: 'Support par visio',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Support prioritaire par chat',
          type: 'check',
          values: [false, false, true, true],
        }, {
          title: 'Conseiller dédié',
          type: 'check',
          values: [false, false, false, true],
        }],
      },
    ]
  }
}
