import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { TrackAdvertising } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface TrackingState {}

export const state = (): TrackingState => ({})

export const getters: GetterTree<TrackingState, RootState> = {}

export const mutations: MutationTree<TrackingState> = {}

export const actions: ActionTree<TrackingState, RootState> = {
  // eslint-disable-next-line no-empty-pattern
  track ({ rootGetters }, payload: TrackAdvertising) {
    try {
      const user = rootGetters['auth/user']
      if (user) {
        this.$api.advertising.track(payload)
      }
    } catch {}
  },

  trackNew ({ rootGetters }, payload: any) {
    try {
      const user = rootGetters['auth/user']
      if (user) {
        this.$api.advertising.trackV2({
          ...payload,
          screenHeight: window.screen.height,
          screenWidth: window.screen.width,
        })
      } else {
        this.$api.advertising.trackUnauthenticatedV2({
          ...payload,
          screenHeight: window.screen.height,
          screenWidth: window.screen.width,
        })
      }
    } catch {}
  },

  async identify (_) {
    try {
      await this.$api.advertising.identify()
    } catch {}
  },
}
