export enum CurrencyCode {
  EUR = 'EUR',
  GBP = 'GBP',
  CHF = 'CHF',
  USD = 'USD',
  AUD = 'AUD',
  CAD = 'CAD',
  JPY = 'JPY',
  CNH = 'CNH',
  CZK = 'CZK',
  DKK = 'DKK',
  BGN = 'BGN',
  PLN = 'PLN',
  HUF = 'HUF',
  RON = 'RON',
  SEK = 'SEK',
  NOK = 'NOK',
  TRY = 'TRY',
  BRL = 'BRL',
  HKD = 'HKD',
  ILS = 'ILS',
  INR = 'INR',
  KRW = 'KRW',
  MXN = 'MXN',
}
