import { useQuery, useQueryClient, useMutation } from '@tanstack/vue-query'
import { useContext } from '@nuxtjs/composition-api'
import axios from 'axios'
import {
  CreateOrganization, CreateOrganizationContact, UpdateOrganizationNotes,
  UpdateOrganization,
  UpdateOrganizationContact,
} from '@abby/shared'

import { Ref } from 'vue'
import { OrganizationHttpMapper } from '~/logic/contexts/client/infrastructure/mapper/organizationHttp.mapper'
import { ContactItemHttpMapper } from '~/logic/contexts/client/infrastructure/mapper/contactItemHttp.mapper'
import { ClientStatisticsHttpMapper } from '~/logic/contexts/client/infrastructure/mapper/clientStatisticsHttp.mapper'
import { ContactHttpMapper } from '~/logic/contexts/client/infrastructure/mapper/contactHttp.mapper'
import { OrganizationForm } from '~/logic/contexts/client/domain/form/organization.form'
import { OrganizationContactForm } from '~/logic/contexts/client/domain/form/organizationContact.form'
import { OrganizationItemHttpMapper } from '~/logic/contexts/client/infrastructure/mapper/organizationItemHttp.mapper'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export type OrganizationFilterQuery = {
  search: Ref<string | undefined>,
  archived: Ref<boolean>,
}

export type OrganizationPaginateQuery = {
  page: Ref<number>,
  limit: Ref<number>,
} & OrganizationFilterQuery

export const useOrganizationRepository = () => {
  const queryClient = useQueryClient()
  const alertManager = useAlertManager()
  const { $backend } = useContext()

  const paginate = ({ page, limit, search, archived }: OrganizationPaginateQuery) => {
    return useQuery({
      refetchOnWindowFocus: false,
      queryKey: ['organization', { page, limit, search, archived }],
      queryFn: async ({ signal }) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        signal?.addEventListener('abort', () => source.cancel())
        const data = await $backend.organization.paginate({
          page: page.value,
          search: search.value?.length ? search.value : undefined,
          limit: limit.value,
          archived: archived.value,
          cancelToken: source.token,
        })
        return {
          ...data,
          docs: data.docs.map(OrganizationItemHttpMapper.toDomain),
        }
      },
      onError: (error) => {
        alertManager.autoError(error)
      },
      keepPreviousData: true,
    })
  }

  const refreshPaginate = async () => {
    await queryClient.invalidateQueries(['organization'])
  }

  const { mutateAsync: archiveOrganization } = useMutation({
    mutationFn: (id: string) => $backend.organization.archive(id),
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: createOrganization } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationFn: (newOrganization: CreateOrganization) => $backend.organization.create(newOrganization),
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: updateOrganization } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationFn: async (organization: OrganizationForm) => {
      if (!organization?.id) {
        return
      }
      const updateOrganization: UpdateOrganization = {
        name: organization.name,
        commercialName: organization.commercialName,
        siret: organization.siret,
        vatNumber: organization.vatNumber,
        billingAddress: organization.billingAddress,
        deliveryAddress: organization.deliveryAddress,
        bankInformations: organization.bankInformations,
        preferences: organization.preferences,
      }
      const result = await $backend.organization.update(organization.id, updateOrganization)
      return OrganizationHttpMapper.toDomain(result)
    },
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: unarchiveOrganization } = useMutation({
    mutationFn: (id: string) => $backend.organization.unarchive(id),
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: removeOrganization } = useMutation({
    mutationFn: (id: string) => $backend.organization.remove(id),
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const getOrganizationById = (id: string) => $backend.organization.fetchOrganization(id)

  const getOrganizationStatisticsById = async (id: string) => {
    const result = await $backend.organization.fetchOrganizationStatistics(id)
    return ClientStatisticsHttpMapper.toDomain(result)
  }

  const getOrganizationContacts = async (id: string) => {
    const results = await $backend.organization.fetchOrganizationContacts(id)
    return results.map(ContactItemHttpMapper.toDomain)
  }

  const updateOrganizationNotes = async (id: string, notes: UpdateOrganizationNotes) => {
    return await $backend.organization.updateOrganizationNotes(id, notes)
  }

  const { mutateAsync: createContact } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationFn: async ({ organizationId, payload }: { organizationId: string, payload: OrganizationContactForm }) => {
      const updateOrganizationContact: CreateOrganizationContact = {
        firstname: payload.firstname,
        lastname: payload.lastname,
        emails: payload.emails,
        phone: payload.phone,
        jobTitle: payload.jobTitle,
      }
      const result = await $backend.organization.createContact(organizationId, updateOrganizationContact)
      return ContactHttpMapper.toDomain(result)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['contact'])
    },
  })

  const { mutateAsync: updateContact } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationFn: async (organizationContact: OrganizationContactForm) => {
      if (!organizationContact.id) {
        return
      }
      const updateOrganizationContact: UpdateOrganizationContact = {
        firstname: organizationContact.firstname,
        lastname: organizationContact.lastname,
        emails: organizationContact.emails,
        phone: organizationContact.phone,
        jobTitle: organizationContact.jobTitle,
        defaultContact: organizationContact.defaultContact,
      }
      const result = await $backend.organization.updateContact(organizationContact.id, updateOrganizationContact)
      return ContactHttpMapper.toDomain(result)
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries(['contact'])
    },
  })

  return {
    paginate,
    getOrganizationById,
    getOrganizationContacts,
    getOrganizationStatisticsById,
    updateOrganizationNotes,
    archiveOrganization,
    unarchiveOrganization,
    removeOrganization,
    createOrganization,
    updateOrganization,
    createContact,
    updateContact,
  }
}
