import { Resource } from '../Resource';
import {
  CreatePersonalServiceActivity,
  CreateProduct,
  ReadPaginatedCatalog,
  ReadProduct, UpdatePersonalServiceActivity,
  UpdateProduct,
} from '../../dto';

type QueryParams<T> = {
  cancelToken?: any;
} & T;

type ProductFilterQuery = {
  search?: string,
};

export class Product extends Resource {
  paginate(query: QueryParams<{
    page: number,
    limit: number,
  } & ProductFilterQuery>): Promise<ReadPaginatedCatalog> {
    const { cancelToken, ...params } = query;
    return this.http.$get('/v2/catalog', { params, cancelToken });
  }

  fetchProduct(id: string): Promise<ReadProduct> {
    return this.http.$get(`v2/catalog/product/${id}`);
  }

  createProduct(product: CreateProduct): Promise<ReadProduct> {
    return this.http.$post('/v2/catalog/product', product);
  }

  updateProduct(product: UpdateProduct): Promise<ReadProduct> {
    return this.http.$put(`/v2/catalog/product/${product.id}`, product);
  }

  createProductPersonalServiceActivity(product: CreatePersonalServiceActivity): Promise<ReadProduct> {
    return this.http.$post('/v2/catalog/personal-service-activity', product);
  }

  updateProductPersonalServiceActivity(product: UpdatePersonalServiceActivity): Promise<ReadProduct> {
    return this.http.$put(`/v2/catalog/personal-service-activity/${product.id}`, product);
  }

  removeProduct(id: string): Promise<void> {
    return this.http.$delete(`/v2/catalog/product/${id}`);
  }
}
