import { IAdditionalAllowedFeature } from '../types/Interface/models/IAdditionalAllowedFeature';
import { ReadCompanyStripeProduct } from '../types/Interface/api/stripe/ReadCompanyStripeProduct';
import { StripeProductType } from '../types/Enum/StripeProductType';
import {
  AbbyPlans,
  FeaturePlan,
  FeaturesByPlan,
  NoWithTrialFeatures,
  PlanFeature,
  PlansConfig,
} from '../types/Enum/Plans';

import { toArray } from './arrayUtils';

const dayjs = require('dayjs');

export const productionDatePackaging = dayjs('2022-06-22');
export const productionDateSynchroInPro = dayjs('2022-09-07');
export const productionDatePaidCreation = dayjs('2022-09-21');
export const productionPricingABCTesting = dayjs('2023-06-31');
export const productionPricingWithoutTax = dayjs('2023-11-30');
export const productionDatePlanFeatureBillingTVA = dayjs('2024-01-01');
export const productionDatePlanFeatureUrssafDeclaration = dayjs('2024-01-01');
export const numberOfDayAfterPaymentFailedBeforeLocking = 15;

const sortProducts = (companyStripeProduct: ReadCompanyStripeProduct[]) => companyStripeProduct
  .slice(0)
  .sort(
    (a, b) => new Date(b.createdAt || '').getTime() - new Date(a.createdAt || '').getTime(),
  );

export function has(companyStripeProduct: ReadCompanyStripeProduct[], plans: StripeProductType | StripeProductType[]) {
  const _plans = toArray(plans);
  return _plans.some((productId: StripeProductType) => {
    const companyProduct = sortProducts(companyStripeProduct).find((p) => p.productId === productId);
    if (!companyProduct || companyProduct.deletedAt) { return false; }

    if (companyProduct.product?.recurring) {
      if (companyProduct.subscriptionId) { return true; }
      const isTrialActive = companyProduct.trialAt && dayjs(companyProduct.trialAt).diff(dayjs()) > 0;
      const isExtendedTrialActive = companyProduct.extendedTrialAt && dayjs(companyProduct.extendedTrialAt).diff(dayjs()) > 0;
      return companyProduct.product?.recurring && (isTrialActive || isExtendedTrialActive);
    }
    return !!companyProduct;
  });
}
export function isTrial(companyStripeProduct: ReadCompanyStripeProduct[], productId: StripeProductType) {
  const product = sortProducts(companyStripeProduct).find((p) => p.productId === productId && !p.deletedAt && !p.subscriptionId);
  return product && dayjs(product.extendedTrialAt || product.trialAt).diff(dayjs()) >= 0;
}

export function isExtendedTrial(companyStripeProduct: ReadCompanyStripeProduct[], productId: StripeProductType) {
  const product = sortProducts(companyStripeProduct).find((p) => p.productId === productId && !p.deletedAt && !p.subscriptionId);
  return product && dayjs(product.extendedTrialAt).diff(dayjs()) >= 0;
}

export function canExtendTrial(companyStripeProduct: ReadCompanyStripeProduct[], productId: StripeProductType) {
  const product = sortProducts(companyStripeProduct).find((p) => p.productId === productId && !p.deletedAt);
  return product && !product.extendedTrialAt && dayjs(product.trialAt).diff(dayjs()) <= 0;
}

export function isTrialExpired(companyStripeProduct: ReadCompanyStripeProduct[], productId: StripeProductType) {
  const product = sortProducts(companyStripeProduct).find((p) => [AbbyPlans.ABBY_START, AbbyPlans.ABBY_PRO].includes(p.productId as unknown as AbbyPlans) && !p.deletedAt);
  return product && dayjs(product.extendedTrialAt || product.trialAt).diff(dayjs()) <= 0;
}

export function whichPlanHasExpired(companyStripeProduct: ReadCompanyStripeProduct[]): AbbyPlans | null {
  const product = sortProducts(companyStripeProduct).find((p) => [AbbyPlans.ABBY_START, AbbyPlans.ABBY_PRO].includes(p.productId as unknown as AbbyPlans) && !p.deletedAt);
  const expired = product && dayjs(product.trialAt).diff(dayjs()) <= 0;
  return expired ? product?.productId as unknown as AbbyPlans : null;
}

export function isExtendedTrialExpired(companyStripeProduct: ReadCompanyStripeProduct[], productId: StripeProductType) {
  const product = sortProducts(companyStripeProduct).find((p) => p.productId === productId && !p.deletedAt);
  return product && dayjs(product.extendedTrialAt).diff(dayjs()) <= 0;
}

export function isSuspended(companyStripeProduct: ReadCompanyStripeProduct[], productId: StripeProductType) {
  const product = sortProducts(companyStripeProduct).find((p) => p.productId === productId);
  return product && !!product.deletedAt;
}

export function timeRemainingBeforeTrialEnd(companyStripeProduct: ReadCompanyStripeProduct[]): { days: number, hours: number, minutes: number, seconds: number } {
  const product = sortProducts(companyStripeProduct).find((p) => [AbbyPlans.ABBY_START, AbbyPlans.ABBY_PRO].includes(p.productId as unknown as AbbyPlans) && !p.deletedAt);
  if (!product) {
    return {
      days: 0, hours: 0, minutes: 0, seconds: 0,
    };
  }
  let delta = dayjs(product.extendedTrialAt || product.trialAt).diff(dayjs(), 'seconds');

  // calculate (and subtract) whole days
  const days = Math.floor(delta / 86400);
  delta -= days * 86400;

  // calculate (and subtract) whole hours
  const hours = Math.floor(delta / 3600) % 24;
  delta -= hours * 3600;

  // calculate (and subtract) whole minutes
  const minutes = Math.floor(delta / 60) % 60;
  delta -= minutes * 60;

  // what's left is seconds
  const seconds = delta % 60; // in theory the modulus is not required

  if ((Number.isNaN(days) && Number.isNaN(hours) && Number.isNaN(minutes) && Number.isNaN(seconds)) || days < 0) {
    return {
      days: 0, hours: 0, minutes: 0, seconds: 0,
    };
  }

  return {
    days, hours, minutes, seconds,
  };
}

export function whichPlanCompanyHas(companyStripeProduct: ReadCompanyStripeProduct[]): AbbyPlans {
  let plan: AbbyPlans = AbbyPlans.ABBY_FREE;
  if (has(companyStripeProduct, StripeProductType.ABBY_BUSINESS)) {
    plan = AbbyPlans.ABBY_BUSINESS;
  } else if (has(companyStripeProduct, StripeProductType.ABBY_PRO)) {
    plan = AbbyPlans.ABBY_PRO;
  } else if (has(companyStripeProduct, [StripeProductType.ABBY_START, StripeProductType.ABBY_CREATION_START])) {
    plan = AbbyPlans.ABBY_START;
  } else if (has(companyStripeProduct, [StripeProductType.ABBY_CREATION_START_PREMIUM])) {
    plan = AbbyPlans.ABBY_PRO;
  }
  return plan;
}

export function isLockedBecauseOfFailedPayment(companyStripeProduct: ReadCompanyStripeProduct[]): boolean {
  const companyPlan = whichPlanCompanyHas(companyStripeProduct);
  const product = companyStripeProduct.find((p) => p.productId === (companyPlan as unknown as StripeProductType) && !p.deletedAt);
  return !!(product?.lastFailedAt && dayjs().diff(product?.failedBillingDueDate, 'd') >= numberOfDayAfterPaymentFailedBeforeLocking);
}

export function hasAccessTo(companyStripeProduct: ReadCompanyStripeProduct[], feature: PlanFeature, data?: {
  createdAt?: Date | string,
  additionalAllowedFeature: Array<IAdditionalAllowedFeature> | undefined,
  totalOpportunities?: number,
  totalTimeRecords?: number,
  hasVat?: boolean;
}) {
  let companyPlan = whichPlanCompanyHas(companyStripeProduct);
  const product = companyStripeProduct.find((p) => p.productId === (companyPlan as unknown as StripeProductType));
  if (isLockedBecauseOfFailedPayment(companyStripeProduct)) companyPlan = AbbyPlans.ABBY_FREE;

  // DÉBUT : Gestion des particularités
  // Permet de gérer les acomptes pour les utilisateurs déjà existants
  if (feature === PlanFeature.BILLING_ADVANCE_CREATION && data?.createdAt && dayjs(data.createdAt).diff(productionDatePackaging, 'd') <= 0) {
    return true;
  }

  // Permet de gérer la création avant la mise en prod de la création payante
  if (feature === PlanFeature.CREATION_CREATE_MICRO && data?.createdAt && dayjs(data.createdAt).diff(productionDatePaidCreation, 'd') <= 0) {
    return true;
  }

  // Permet de gérer la création avant la mise en prod de la création payante
  if ([PlanFeature.ADMINISTRATION_CFE_ACRE, PlanFeature.CREATION_PRIORITY].includes(feature) && has(companyStripeProduct, [StripeProductType.ABBY_PLUS, StripeProductType.ABBY_CONTACT]) && data?.createdAt && dayjs(data.createdAt).diff(productionDatePaidCreation, 'd') <= 0) {
    return true;
  }

  // Permet de gérer la création avant la mise en prod de la création payante
  if (feature === PlanFeature.CREATION_CALL_EXPERT && has(companyStripeProduct, [StripeProductType.ABBY_CONTACT, StripeProductType.MEETING_WITH_AN_EXPERT]) && !companyStripeProduct.find((p) => [StripeProductType.ABBY_CONTACT, StripeProductType.MEETING_WITH_AN_EXPERT].includes(p.productId))?.consumedAt) {
    return true;
  }

  // Permet de gérer ceux qui Plus et Contact pour le chat
  if (feature === PlanFeature.SUPPORT_CHAT && has(companyStripeProduct, [StripeProductType.ABBY_PLUS, StripeProductType.ABBY_CONTACT])) {
    return true;
  }

  // Permet de gérer ceux qui ont acheté des formations unitairement
  if (feature === PlanFeature.PRODUCTIVITY_ABBY_ACADEMY && has(companyStripeProduct, [StripeProductType.ABBY_ACADEMIE_CREATION, StripeProductType.ABBY_ACADEMIE_MARKETING_COMMUNICATION, StripeProductType.ABBY_PLUS, StripeProductType.ABBY_CONTACT])) {
    return true;
  }

  // Permet de gérer ceux qui ont acheté un template de facturation seul
  if (feature === PlanFeature.BILLING_TEMPLATE_CUSTOMISATION && has(companyStripeProduct, [StripeProductType.BILLING_TEMPLATE_2, StripeProductType.BILLING_TEMPLATE_3])) {
    return true;
  }

  // Permet de gérer les entreprises à la TVA devant posséder Abby Pro pour finaliser des factures
  if (feature === PlanFeature.BILLING_VAT && data?.createdAt && dayjs(data.createdAt).diff(productionDatePlanFeatureBillingTVA, 'd') <= 0) {
    return true;
  }

  if (feature === PlanFeature.BILLING_VAT && !data?.hasVat) {
    return true;
  }

  // Permet de gérer la déclaration Urssaf dans le plan Abby Start
  if (feature === PlanFeature.ACCOUNTING_URSSAF_DECLARATION && data?.createdAt && dayjs(data.createdAt).diff(productionDatePlanFeatureUrssafDeclaration, 'd') <= 0) {
    return true;
  }

  // SECTION : Fonctionnalités ajoutées manuellement
  if (data?.additionalAllowedFeature?.map((el) => el.feature).includes(feature)) {
    return true;
  }

  // SECTION : Gestion des cotas
  if (feature === PlanFeature.PRODUCTIVITY_OPPORTUNITY_BOARD) {
    return PlansConfig[companyPlan].opportunity.max === -1 ? true : (data?.totalOpportunities || 0) < PlansConfig[companyPlan].opportunity.max;
  }

  if (feature === PlanFeature.PRODUCTIVITY_TIME_TRACKING) {
    return PlansConfig[companyPlan].timeTracking.max === -1 ? true : (data?.totalTimeRecords || 0) < PlansConfig[companyPlan].timeTracking.max;
  }

  // Permet de gérer les gens qui se sont inscrits avant la migration et de leur laisser la synchro
  if (feature === PlanFeature.ACCOUNTING_BANKING_SYNCHRONIZATION && data?.createdAt && dayjs(data.createdAt).diff(productionDateSynchroInPro, 'd') <= 0 && companyPlan === AbbyPlans.ABBY_START) {
    return true;
  }

  // Permet de gérer les nouveaux verrous pours les prochains inscrits uniquement
  if ([PlanFeature.BILLING_API_TIERS_PRESTATION, PlanFeature.BILLING_REMINDER, PlanFeature.BILLING_FREQUENCY].includes(feature) && data?.createdAt && dayjs(data.createdAt).diff(productionPricingABCTesting, 'd') <= 0 && companyPlan === AbbyPlans.ABBY_START) {
    return true;
  }
  // FIN : Gestion des particularités

  // DÉBUT : Gestion des exclusions
  if ((product?.trialAt || product?.extendedTrialAt) && NoWithTrialFeatures.includes(feature)) {
    return false;
  }
  // FIN : Gestion des exclusions

  return !!PlansConfig[companyPlan].features?.find((f) => f === feature);
}

export function whichPlanFor(feature: PlanFeature, data?: { createdAt?: Date | string }) {
  // Permet de gérer les gens qui se sont inscrits avant la migration et de leur laisser la synchro
  if (feature === PlanFeature.ACCOUNTING_BANKING_SYNCHRONIZATION && data?.createdAt && dayjs(data.createdAt).diff(productionDateSynchroInPro, 'd') <= 0) {
    return StripeProductType.ABBY_START;
  }

  const featurePlan: FeaturePlan[] = Object.entries(FeaturesByPlan).reduce((acc: FeaturePlan[], [plan, features]: any) => [
    ...(acc || []),
    ...features.map((f: PlanFeature) => ({ plan, feature: f })),
  ], []);

  return featurePlan?.find((f) => f.feature === feature)?.plan || StripeProductType.ABBY_FREE;
}

export function whichPlansSupport(feature: PlanFeature) {
  const featurePlan: FeaturePlan[] = Object.entries(PlansConfig).reduce((acc: any[], [plan, value]: any) => {
    value.features.forEach(((f: any) => {
      if (!acc?.find((a) => a.feature === f)) {
        acc.push({ feature: f, plan: [plan] });
      } else {
        acc?.find((a) => a.feature === f)?.plan?.push(plan);
      }
    }));
    return acc;
  }, []);

  return featurePlan?.find((f) => f.feature === feature)?.plan || [StripeProductType.ABBY_FREE, StripeProductType.ABBY_START, StripeProductType.ABBY_PRO];
}
