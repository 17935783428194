import { useContext } from '@nuxtjs/composition-api'
import { IBilling } from '@abby/core-legacy'
import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'
import { useBillingStore } from '~/logic/contexts/billing/infrastructure/store/billingPinia.store'
import {
  useAccountingBillingRepository,
} from '~/logic/contexts/billing/infrastructure/repository/accountingBilling.repository'

export const useOpenBillingMarkAsPaidModal = () => {
  const billingStore = useBillingStore()
  const accountingBillingRepository = useAccountingBillingRepository()
  const { $api } = useContext()

  return async ({ billingItem, legacyBilling }: {billingItem?: BillingItem, legacyBilling?: IBilling }) => {
    const billing = billingItem && !legacyBilling ? await $api.billing.get(billingItem.id) : legacyBilling
    const details = await accountingBillingRepository.fetchBillingDetails(billing as IBilling)
    const payments = await accountingBillingRepository.fetchInvoicePayments(billing?._id as string)
    if (!details) { return }
    return billingStore.toggleMarkAsPaidModal(true, { billing, details, payments })
  }
}
