import { ProductUnit, ProductType, VatCode, CurrencyCode, Money, CurrencyOrigin } from '@abby/shared'

type BillingDetailsLine = {
  id: string;
  unitPrice: Money;
  quantity?: number;
  quantityUnit?: ProductUnit;
  designation: string;
  reference?: string;
  description?: string;
  type?: ProductType;
  vatCode?: VatCode;
  priceWithoutTaxBeforeDiscount: Money;
  discountAmount?: Money;
  priceWithoutTaxAfterDiscount: Money;
  vatAmount: number;
  priceTotalTax: Money;
  // convertedAmounts?: {
  //   priceWithoutTaxBeforeDiscount: Money;
  //   discountAmount?: Money;
  //   priceWithoutTaxAfterDiscount: Money;
  //   vatAmount: number;
  //   priceTotalTax: Money;
  // }
}

type BillingDetailCurrency = {
  currencyCode: CurrencyCode;
  currencyDate: Date;
  currencyRate: number
  origin: CurrencyOrigin
}

type BillingDetailTotal = {
  totalAmountWithoutTaxBeforeDiscount: Money;
  totalAmountWithoutTaxAfterDiscount: Money;
  totalAmountWithTaxAfterDiscount: Money;
  remainingAmountWithoutTax: Money;
  remainingAmountWithTax: Money;
  vatDetails: {
    vatCode: VatCode;
    amount: Money;
  }[]
  advances?: {
    number: string;
    totalAmountWithTax: Money;
  }[]
  discountAmount?: Money;
  // convertedAmounts?: {
  //   totalAmountWithoutTaxBeforeDiscount: Money;
  //   totalAmountWithoutTaxAfterDiscount: Money;
  //   remainingAmountWithoutTax: Money;
  //   remainingAmountWithTax: Money;
  //   vatDetails: {
  //     vatCode: VatCode;
  //     amount: Money;
  //   }[]
  //   advances?: {
  //     number: string;
  //     totalAmountWithTax: Money;
  //   }[]
  //   discountAmount?: Money;
  // }
}

type BillingDetailsProps = {
  id: string;
  lines: BillingDetailsLine[];
  currency: BillingDetailCurrency;
  total: BillingDetailTotal;
  remainingReconciliateAmount: Money;
}

export class BillingDetails {
  readonly id: string;
  readonly lines: BillingDetailsLine[];
  readonly currency: BillingDetailCurrency;
  readonly total: BillingDetailTotal;
  readonly remainingReconciliateAmount: Money;

  private constructor (props: BillingDetailsProps) {
    this.id = props.id
    this.lines = props.lines
    this.currency = props.currency
    this.total = props.total
    this.remainingReconciliateAmount = props.remainingReconciliateAmount
  }

  static create (props: BillingDetailsProps): BillingDetails {
    return new BillingDetails(props)
  }

}
