
import { Component, Vue, Prop } from 'nuxt-property-decorator'
import VimeoPlayer from '~/components-legacy/academy/VimeoPlayer.vue'

@Component({
  components: { VimeoPlayer },
})
export default class CallToAction extends Vue {
  @Prop()
  overline!: string

  @Prop()
  title!: string

  @Prop()
  description!: string

  @Prop()
  image!: { src: string }

  @Prop()
  video!: { src: string }

  @Prop()
  keys!: Array<string>

  @Prop()
  primaryAction!: { title: string }

  @Prop()
  secondaryAction!: { title: string }
}
