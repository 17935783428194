import { useProductRepository } from '~/logic/contexts/product/infrastructure/repository/product.repository'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'
import { ProductForm } from '~/logic/contexts/product/domain/form/product.form'

export const useCreateProduct = () => {
  const productRepository = useProductRepository()
  const alertManager = useAlertManager()

  return async (product: ProductForm) => {
    const newProduct = await productRepository.createProduct(product)
    alertManager.success('Le produit a bien été créé')
    return newProduct
  }
}
