import { Resource } from '../Resource';
import {
  CreateOrganization,
  CreateOrganizationContact, ReadContact,
  ReadContactListItem, ReadCustomerStatistics,
  ReadOrganization,
  ReadPaginatedOrganizations, UpdateOrganization, UpdateOrganizationContact,
  UpdateOrganizationNotes,
} from '../../dto';

type QueryParams<T> = {
  cancelToken?: any;
} & T;

type OrganizationFilterQuery = {
  search?: string,
};

export class Organization extends Resource {
  paginate(query: QueryParams<{
    page: number,
    limit: number,
    archived: boolean,
  } & OrganizationFilterQuery>): Promise<ReadPaginatedOrganizations> {
    const { cancelToken, ...params } = query;
    return this.http.$get('/organizations', { params, cancelToken });
  }

  fetchOrganization(id: string): Promise<ReadOrganization> {
    return this.http.$get(`/organization/${id}`);
  }

  fetchOrganizationStatistics(id: string): Promise<ReadCustomerStatistics> {
    return this.http.$get(`/v2/customer/organization/${id}/statistics`);
  }

  fetchOrganizationContacts(id: string): Promise<ReadContactListItem[]> {
    return this.http.$get(`/organization/${id}/contacts`);
  }

  updateOrganizationNotes(id: string, payload: UpdateOrganizationNotes): Promise<ReadOrganization> {
    return this.http.$patch(`/organization/${id}/notes`, payload);
  }

  archive(id: string): Promise<void> {
    return this.http.$patch(`/organization/${id}/archive`);
  }

  unarchive(id: string): Promise<void> {
    return this.http.$patch(`/organization/${id}/unarchive`);
  }

  remove(id: string): Promise<void> {
    return this.http.$delete(`/organization/${id}`);
  }

  create(organization: CreateOrganization): Promise<ReadOrganization> {
    return this.http.$post('/organization', organization);
  }

  update(organizationId: string, organization: UpdateOrganization): Promise<ReadOrganization> {
    return this.http.$put(`/organization/${organizationId}`, organization);
  }

  createContact(id: string, organizationContact: CreateOrganizationContact): Promise<ReadContact> {
    return this.http.$post(`/organization/${id}/contact`, organizationContact);
  }

  updateContact(id: string, organizationContact: UpdateOrganizationContact): Promise<ReadContact> {
    return this.http.$put(`/organization/contact/${id}`, organizationContact);
  }
}
