import { GuideStepItem } from '@abby/core-legacy'
import { useBillingRepository } from '~/logic/contexts/billing/infrastructure/repository/billing.repository'
import { useBillingStore } from '~/logic/contexts/billing/infrastructure/store/billingVuex.store'
import { useConfirmManager } from '~/composables/shared/manager/useConfirmManager'
import { useHelpManager } from '~/composables/shared/manager/useHelpManager'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export const useDisableBillingTestMode = () => {
  const confirmManager = useConfirmManager()
  const helpManager = useHelpManager()
  const alertManager = useAlertManager()
  const billingRepository = useBillingRepository()
  const billingStore = useBillingStore()

  return async () => {
    const confirm = await confirmManager.ask({
      title: 'Désactiver le mode test',
      content: '<p>Êtes-vous sûr de vouloir désactiver le mode test du logiciel de facturation ?</p><p class="mb-0">Si vous éditez un document, cette action fermera le document en cours d\'édition.</p>',
      confirmText: 'C\'est parti !',
      confirmButton: { depressed: true, color: 'primary' },
    })
    if (!confirm) {
      return
    }
    await billingRepository.disableTestMode()
    await helpManager.completeGuideStep(GuideStepItem.DISABLE_TEST_MODE)
    alertManager.success('Le mode test du logiciel de facturation a été désactivé')
  }
}
