import { ReadBillingCollectionItem, Money } from '@abby/shared'
import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'
import { CustomerHttpMapper } from '~/logic/contexts/billing/infrastructure/mapper/customerHttp.mapper'
import { PaymentRequest } from '~/logic/contexts/billing/domain/entity/paymentRequest.entity'

export class BillingItemHttpMapper {
  static toDomain (doc: ReadBillingCollectionItem, options?: { adminActionsActivated: boolean }) {
    return BillingItem
      .create({
        id: doc.id,
        parent: doc.parent
          ? {
            id: doc.parent.id,
            number: doc.parent.number,
            type: doc.parent.type,
          }
          : undefined,
        createdFromInvoice: doc.createdFromInvoice,
        title: doc.title,
        state: doc.state,
        emittedAt: new Date(doc.emittedAt * 1000),
        paidAt: doc.paidAt ? new Date(doc.paidAt * 1000) : undefined,
        signedAt: doc.signedAt ? new Date(doc.signedAt * 1000) : undefined,
        refusedAt: doc.refusedAt ? new Date(doc.refusedAt * 1000) : undefined,
        expiredAt: doc.expiredAt ? new Date(doc.expiredAt * 1000) : undefined,
        dueAt: doc.dueAt ? new Date(doc.dueAt * 1000) : undefined,
        archivedAt: doc.archivedAt ? new Date(doc.archivedAt * 1000) : undefined,
        nextBillingAt: doc.nextBillingAt ? new Date(doc.nextBillingAt * 1000) : undefined,
        withElectronicSignature: doc.withElectronicSignature,
        useOnlinePayment: doc.isOnlinePaymentActivated,
        adminActionsActivated: options?.adminActionsActivated,
        number: doc.number,
        type: doc.type,
        locked: doc.locked,
        finalizable: doc.finalizable,
        locale: doc.locale,
        currency: doc.currencyCode,
        opportunity: doc.opportunity
          ? {
            id: doc.opportunity.id,
            name: doc.opportunity.name,
          }
          : undefined,
        customer: CustomerHttpMapper.toDomain(doc.customer),
        tiersPrestationIsActivatedForThisBilling: doc.tiersPrestationIsActivatedForThisBilling,
        paymentRequest: doc.paymentRequest
          ? PaymentRequest.create({
            mntVirement: doc.paymentRequest.mntVirement,
            advanceAlreadyPayed: doc.paymentRequest.advanceAlreadyPayed || 0,
            state: doc.state,
            statut: doc.paymentRequest.statut as any,
            dateVirement: doc.paymentRequest.dateVirement ? new Date(doc.paymentRequest.dateVirement * 1000) : undefined,
            dateDebutEmploi: new Date(doc.paymentRequest.dateDebutEmploi * 1000),
            dateFinEmploi: new Date(doc.paymentRequest.dateFinEmploi * 1000),
            idDemandePaiement: doc.paymentRequest.idDemandePaiement,
            customerRegisteredAt: new Date(doc.customer.tiersPrestation!.registeredAt * 1000),
          })
          : undefined,
        amount: {
          totalAmountWithoutTaxAfterDiscount: doc.totalAmountWithoutTaxAfterDiscount,
          totalAmountWithTaxAfterDiscount: doc.totalAmountWithTaxAfterDiscount,
          remainingAmountWithoutTax: doc.remainingAmountWithoutTax,
          remainingAmountWithTax: doc.remainingAmountWithTax,
          billedAmountWithoutTax: doc.billedAmountWithoutTax,
          billedAmountWithTax: doc.billedAmountWithTax,
          cancelledAmountWithoutTax: doc.cancelledAmountWithoutTax || 0,
          cancelledAmountWithTax: doc.cancelledAmountWithTax || 0,
          ...(doc.remainingReconciliateAmount
            ? {
              remainingReconciliateAmount: Money.create({ amountInCents: doc.remainingReconciliateAmount }),
            }
            : {}),
        },
        currencyAmount: doc.convertedAmounts
          ? {
            totalAmountWithoutTaxAfterDiscount: doc.convertedAmounts.totalAmountWithoutTaxAfterDiscount,
            totalAmountWithTaxAfterDiscount: doc.convertedAmounts.totalAmountWithTaxAfterDiscount,
            remainingAmountWithoutTax: doc.convertedAmounts.remainingAmountWithoutTax,
            remainingAmountWithTax: doc.convertedAmounts.remainingAmountWithTax,
            cancelledAmountWithTax: 0,
            cancelledAmountWithoutTax: 0,
          }
          : undefined,
        lastSendByEmailAt: doc.lastSendByEmailAt ? new Date(doc.lastSendByEmailAt * 1000) : undefined,
        lastDownloadAt: doc.lastDownloadAt ? new Date(doc.lastDownloadAt * 1000) : undefined,
        lastRemindedAt: doc.lastReminderSentAt ? new Date(doc.lastReminderSentAt * 1000) : undefined,
        isReminderActivated: !!doc.isReminderActivated,
        electronicSignature: doc.electronicSignature,
        isEditable: doc.isEditable || false,
      })
  }
}
