import { useContext } from '@nuxtjs/composition-api'
import { IBilling, BillingType as BillingTypeLegacy } from '@abby/core-legacy'
import { ReconciliateInvoice, BillingType } from '@abby/shared'
import { useQueryClient } from '@tanstack/vue-query'
import { BillingDetailsHttpMapper } from '~/logic/contexts/billing/infrastructure/mapper/billingDetailsHttp.mapper'
import { useToasterManager } from '~/composables/shared/manager/useToasterManager'
import { useBillingStore } from '~/logic/contexts/billing/infrastructure/store/billingPinia.store'
import { BillingPaymentHttpMapper } from '~/logic/contexts/billing/infrastructure/mapper/billingPaymentHttp.mapper'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export const useAccountingBillingRepository = () => {
  const { $backend, $api, $move } = useContext()
  const toasterManager = useToasterManager()
  const alertManager = useAlertManager()
  const billingStore = useBillingStore()
  const queryClient = useQueryClient()

  const refreshPaginate = async () => {
    await queryClient.invalidateQueries({
      predicate: query => ['billings', 'billingStatistics'].includes(query.queryKey[0] as string),
    })
  }

  const fetchBillingDetails = async (billing: IBilling) => {
    if (!billing._id) { return }
    let data
    if (billing.billingType === BillingTypeLegacy.INVOICE) {
      data = await $backend.billing.fetchInvoiceDetails(billing._id)
    } else if (billing.billingType === BillingTypeLegacy.ASSET) {
      data = await $backend.billing.fetchAssetDetails(billing._id)
    } else {
      data = await $backend.billing.fetchAdvanceDetails(billing._id)
    }
    return BillingDetailsHttpMapper.toDomain(data)
  }

  const fetchInvoicePayments = async (id: string) => {
    if (!id) { return [] }
    const data = await $backend.billing.fetchInvoicePayments(id)
    return BillingPaymentHttpMapper.toDomain(data)
  }

  const reconciliateBilling = async (
    { id, number, type, partialReconciliate, hasZeroTotalAmount, payload }:
    { id: string, number: string, type: BillingTypeLegacy, partialReconciliate: boolean, hasZeroTotalAmount: boolean, payload: ReconciliateInvoice }) => {
    if (type === BillingTypeLegacy.INVOICE) {
      await $backend.billing.reconciliateInvoice(id, payload)
    } else if (type === BillingTypeLegacy.ASSET) {
      await $backend.billing.reconciliateAsset(id, payload)
    } else {
      await $backend.billing.reconciliateAdvance(id, payload)
    }
    billingStore.toggleMarkAsPaidModal(false)

    const status = partialReconciliate ? 'Paiement partiel' : 'Payé'
    const hasZeroTotalAmountMessage = hasZeroTotalAmount ? '<br><i>Aucune entrée n\'a été ajoutée à votre livre des recettes</i>' : ''
    toasterManager.success({
      title: !hasZeroTotalAmount ? 'Encaissement ajouté à votre livre des recettes' : 'Document à 0 € marqué comme payé',
      message: `Statut du document ${number} passé en <span class="${partialReconciliate ? 'tw-text-primary-base' : 'tw-text-secondary-base'}">${status}</span>${hasZeroTotalAmountMessage}`,
      ...(!hasZeroTotalAmount
        ? {
          button: {
            text: 'Voir dans mon livre des recettes',
            action: async () => {
              await $move('/accounting/books/incomes')
            },
          },
        }
        : {}),
    })
    await refreshPaginate()
  }

  const markBillingAsUnpaid = async (id: string, type: BillingType) => {
    if (type === BillingType.INVOICE) {
      await $backend.billing.markInvoiceAsUnpaid(id)
    } else if (type === BillingType.ASSET) {
      await $backend.billing.markAssetAsUnpaid(id)
    } else {
      await $backend.billing.markAdvanceAsUnpaid(id)
    }
    alertManager.success('Le document a été marqué comme non payé')
    await refreshPaginate()
  }

  return {
    fetchBillingDetails,
    fetchInvoicePayments,
    reconciliateBilling,
    markBillingAsUnpaid,
  }
}
