
import { Component, ModelSync, Prop, Ref, Vue } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'

@Component({
  name: 'as-autocomplete',
  components: {
    ValidationProvider,
  },
})
export default class ASAutocomplete extends Vue {
  @Ref('autocomplete')
  autocomplete!: any;

  @Prop({ default: '' })
  rules!: object | string;

  @Prop({ default: false })
  hideLabel!: boolean | string;

  @Prop({ required: false })
  tooltip!: string;

  @Prop({ default: false })
  items!: Array<{ text: string, value: any }>;

  get _hideLabel () {
    return !!this.hideLabel || typeof this.hideLabel === 'string'
  }

  get hasSlot () {
    return !!this.$slots.tooltip || !!this.$scopedSlots.tooltip
  }

  blur () {
    this.autocomplete.blur()
  }

  @ModelSync('value', 'input')
  innerValue: any;
}
