import { ActionTree, GetterTree, MutationTree } from 'vuex'
import {
  BillingCycle,
  GuideLevelItem,
  GuideStepItem,
  PersonaFeature,
} from '@abby/core-legacy'

import type {
  CreatePersona,
  IGuideLevel,
  IGuideStep, Overwrite,
  ReadPersona,
} from '@abby/core-legacy'

import { RootState } from '~/store/index'

type GuideLevel = Overwrite<IGuideLevel, {
  steps: Overwrite<IGuideStep, { isDisplayable?: boolean }>[]
}>

export interface GuideState {
  guideConfig: IGuideLevel[],
  persona: ReadPersona | null,
  guideSteps: IGuideStep[]
  levelToOpen: GuideLevelItem | null,
  modal : {
    deactivateBillingTestModeModal: boolean,
    urssafDeclarationModal: boolean,
    bankSynchroModal: boolean,
    onlinePaymentModal: boolean,
    immediateAdvanceModal: boolean
  }
}

export const state = (): GuideState => ({
  guideConfig: [],
  persona: {
    abbyDiscoveredFrom: '',
  },
  guideSteps: [],
  levelToOpen: null,
  modal: {
    deactivateBillingTestModeModal: false,
    urssafDeclarationModal: false,
    bankSynchroModal: false,
    onlinePaymentModal: false,
    immediateAdvanceModal: false,
  },
})

export const getters: GetterTree<GuideState, RootState> = {
  modal: state => state.modal,
  countNotCompleted: (_, getters) => {
    return getters.guideConfig?.reduce((acc: any, level: any) => {
      return [...acc, ...level.steps.filter((s: any) => !s.completedAt && s.isDisplayable)]
    }, []).length
  },
  countCompleted: (_, getters) => {
    return getters.guideConfig?.reduce((acc: any, level: any) => {
      return [...acc, ...level.steps.filter((s: any) => s.completedAt)]
    }, []).length
  },
  guideConfig: (state, getters, _, rootGetters) => {
    let xpm = {}
    try {
      // @ts-ignore
      // eslint-disable-next-line no-undef
      xpm = window.$nuxt?.$xpm || {}
    } catch (_) {
      //
    }
    const config = [
      {
        title: 'Fais connaissance avec Abby',
        level: GuideLevelItem.MEET_ABBY,
        duration: 'Moins d\'une min',
        iconUrl: '/onboarding/guide/levels/meet.png',
        steps: [
          {
            step: GuideStepItem.MEET_ABBY,
            title: 'Découvre Abby en 1 minute',
            content: 'Bienvenue sur Abby, un outil 100% conforme qui regroupe tout ce dont tu as besoin pour gérer ta boite et réussir.<br><br> Premièrement, regarde cette courte vidéo qui va te donner un aperçu rapide des étapes à suivre pour t’aider à comprendre et t’installer rapidement sur Abby.',
            iconUrl: '',
            imageUrl: '/discover-abby.png',
            imageBoxShadow: true,
            videoUrl: 'https://vimeo.com/810222535/fdb6f7f29d',
            action: {
              text: 'C\'est parti !',
              icon: 'play_circle',
              iconDirection: 'left',
            },
          },
        ],
      },
      {
        title: 'Facture tes clients en quelques clics',
        duration: 'Environ 8 min',
        level: GuideLevelItem.BILL_YOUR_CUSTOMERS_QUICKLY,
        iconUrl: '/onboarding/guide/levels/facturation.png',
        steps: [
          {
            step: GuideStepItem.CUSTOM_YOUR_THEME,
            title: 'Personnalise le style de tes documents',
            content: 'L’image de ton entreprise est importante. Avoir des beaux documents à leur envoyer est nécessaire. Avec notre outil de facturation tu peux configurer tes documents à ton image.<br><br> Tu vas pouvoir y mettre ton logo, y mettre ta couleur, ajouter un fond et  y faire figurer tes réseaux sociaux.',
            iconUrl: '',
            imageUrl: '/onboarding/guide/steps/theme-document.png',
            videoUrl: '',
            action: {
              text: 'Personnaliser mes documents',
              icon: 'palette',
              iconDirection: 'left',
            },
          },
          {
            step: GuideStepItem.CONFIGURE_YOUR_NUMBERING_FORMAT,
            title: 'Configure ton format de numérotation',
            content: 'Avoir un format et une suite chronologique dans ses numéros de document est obligatoire. Tu vas pouvoir le configurer comme bon te semble.<br><br> Tu as peut-être déjà fait des factures avec un format de numérotation déjà défini ? Si c’est le cas tu vas pouvoir reprendre le même pour l\'utiliser sur Abby.',
            iconUrl: '',
            imageUrl: '/onboarding/guide/steps/numerotation.png',
            videoUrl: '',
            action: {
              text: 'Configurer mon format',
              icon: 'settings',
              iconDirection: 'left',
            },
          },
          {
            step: GuideStepItem.CONFIGURE_YOUR_BILLING_COUNTER,
            title: 'Règle ton compteur de départ',
            content: 'Avoir un format et une suite chronologique dans ses numéros de document est obligatoire. Tu vas pouvoir le configurer comme bon te semble.<br><br> Tu as peut-être déjà fait des factures auparavant ? Si c’est le cas tu vas pouvoir indiquer le prochain numéro de facture à utiliser sur Abby.',
            iconUrl: '',
            imageUrl: '/onboarding/guide/steps/numerotation.png',
            videoUrl: '',
            action: {
              text: 'Régler mon compteur',
              icon: 'settings',
              iconDirection: 'left',
            },
          },
          {
            isDisplayable: [BillingCycle.ESTIMATE_ADVANCE_FINAL_INVOICE, BillingCycle.ESTIMATE_FINAL_INVOICE].includes(getters.persona?.billingCycle),
            step: GuideStepItem.CREATE_YOUR_FIRST_ESTIMATE_OR_PURCHASE_ORDER,
            title: 'Crée ton premier devis ou bon de commande',
            content: `Les devis et les bons de commande font office de contrat entre toi et ton client. Tu vas pouvoir les générer très facilement ici.
                      <br><br>Une fois ton devis finalisé, tu pourras créer un ou plusieurs acomptes puis une facture finale (reste à payer). 
                      <br><br>Si tu veux que tes clients signe plus vite et plus simplement, tu peux mettre utiliser la signature de tes documents en ligne. (+ article)`,
            iconUrl: '',
            imageUrl: '/onboarding/guide/steps/devis.png',
            videoUrl: '',
            action: {
              text: 'Créer un devis',
              icon: 'add',
              iconDirection: 'left',
            },
          },
          {
            isDisplayable: getters.persona?.billingCycle === BillingCycle.ESTIMATE_ADVANCE_FINAL_INVOICE,
            step: GuideStepItem.CREATE_YOUR_FIRST_ADVANCE,
            title: 'Crée ton premier acompte',
            content: `Tu peux très facilement créer une facture d’acompte pour que ton client te règle une partie du montant de ton devis ou de ton bon de commande.
                          <br><br>Tu pourras toujours retrouver les documents qui sont associés entre eux et Abby d’indiquera les montants qui ont déjà été facturés depuis un devis.`,
            iconUrl: '',
            imageUrl: '/onboarding/guide/steps/acompte.png',
            videoUrl: '',
            action: {
              text: 'Créer un acompte',
              icon: 'add',
              iconDirection: 'left',
            },
          },
          {
            isDisplayable: [BillingCycle.ESTIMATE_ADVANCE_FINAL_INVOICE, BillingCycle.ESTIMATE_FINAL_INVOICE].includes(getters.persona?.billingCycle),
            step: GuideStepItem.CREATE_YOUR_FIRST_FINAL_INVOICE_STILL_TO_BE_PAID,
            title: 'Crée ta première facture finale (reste à payer)',
            content: `Dès que tu veux te faire payer le reste du montant d’un devis ou bon de commande, tu peux créer une facture finale (reste à payer). 
                          <br><br>Abby va automatiquement t’indiquer le montant restant à facturer et indiquer dans l’espace total de ton document les numéros et montants des acomptes payés si il y en a.`,
            iconUrl: '',
            imageUrl: '/onboarding/guide/steps/facture.png',
            videoUrl: '',
            action: {
              text: 'Créer une facture finale',
              icon: 'add',
              iconDirection: 'left',
            },
          },
          {
            isDisplayable: getters.persona?.billingCycle === BillingCycle.ONLY_INVOICES,
            step: GuideStepItem.CREATE_YOUR_FIRST_INVOICE,
            title: 'Crée ta première facture',
            content: `Les devis et les bons de commande font office de contrat entre toi et ton client. Tu vas pouvoir les générer très facilement ici.
                      <br><br>Une fois ton devis finalisé, tu pourras créer un ou plusieurs acomptes puis une facture finale (reste à payer). 
                      <br><br>Si tu veux que tes clients signe plus vite et plus simplement, tu peux mettre utiliser la signature de tes documents en ligne. (+ article)`,
            iconUrl: '',
            imageUrl: '/onboarding/guide/steps/facture.png',
            videoUrl: '',
            action: {
              text: 'Créer une facture',
              icon: 'add',
              iconDirection: 'left',
            },
          },
          {
            step: GuideStepItem.SAVE_TIME_BY_CONFIGURING_DEFAULT_INFORMATION,
            title: 'Gagne du temps en configurant tes informations par défaut',
            content: `Si tu veux créer tes documents de facturation encore plus vite et ne pas avoir à configurer ou écrire toujours la même chose, tu peux configurer tes paramètres par défaut qui s’appliqueront automatiquement à tes documents lorsque tu en créera de nouveaux.
                          <br><br>Configure facilement tes informations de paiement, conditions générales, informations complémentaires etc..`,
            iconUrl: '',
            imageUrl: '/onboarding/guide/steps/config-document.png',
            videoUrl: '',
            action: {
              text: 'Configurer mes paramètres',
              icon: 'settings',
              iconDirection: 'left',
            },
          },
          {
            isDisplayable: true,
            step: GuideStepItem.ACTIVATE_ONLINE_PAYMENT_OF_YOUR_INVOICE,
            title: 'Active le paiement en ligne de tes factures',
            content: `Tu veux que tes clients te payent plus vite et plus simplement ? Lorsque que tu décide d’utiliser le paiement en ligne pour un document, un lien sera automatiquement généré pour donner à ton client un accès à son portail client. 
                          <br><br>Tes clients pourront te régler par carte bancaire ou prélèvement SEPA. Tu pourras ensuite effectuer des virements vers ton compte bancaire pour récupérer ta rémunération.`,
            iconUrl: '',
            imageUrl: '/onboarding/guide/steps/paiement-en-ligne.png',
            videoUrl: '',
            action: {
              text: 'Activer le paiement en ligne',
              icon: 'toggle_on',
              iconDirection: 'left',
            },
          }, {
            isDisplayable: getters.persona?.interestedInFeatures?.includes(PersonaFeature.IMMEDIATE_ADVANCE_API) || rootGetters['billingConfiguration/billingConfiguration']?.tiersPrestationActivated,
            step: GuideStepItem.ACTIVATE_URSSAF_IMMEDIATE_ADVANCE,
            title: 'Active l’avance immédiate automatique',
            content: `Tu fais du service à la personne et tu as envie que tes clients bénéficient de l’avance immédiate crédit d'impôt ? Avec Abby tout se fait tout seul !
                          <br><br>Une fois l’avance immédiate activée, tu pourras créer tes clients et tes factures pour qu’ils soient géré automatiquement avec l’Urssaf via l’API de tierce prestation.`,
            iconUrl: '',
            imageUrl: '/onboarding/guide/steps/avance-immediate.png',
            videoUrl: '',
            action: {
              text: 'Configurer l’avance immédiate',
              icon: 'toggle_on',
              iconDirection: 'left',
            },
          },
          {
            isDisplayable: true,
            step: GuideStepItem.IMPORT_YOUR_CLIENTS_AND_INVOICES,
            title: 'Importe tes clients et / ou tes factures',
            content: `Tu as déjà un historique ? Sur Abby tu peux importe tes factures dans ton livre des recettes et ajouter toute ta base de clients.
                          <br><br>Si tu viens d’un autre logiciel, tu devrais pouvoir exporter tes données assez simplement. En cas de problème lors l’import, contacte le support ! On pourra t’aider à t’installer.`,
            iconUrl: '',
            imageUrl: '/onboarding/guide/steps/client.png',
            videoUrl: '',
            action: {
              text: 'Importer mes données',
              icon: 'import_export',
              iconDirection: 'left',
            },
          },
          {
            step: GuideStepItem.DISABLE_TEST_MODE,
            title: 'Désactive le mode test',
            content: `Ça y est tu as pu tester l’outil de facturation comme tu voulais ? Tu es prêts à passer à la suite ? Une fois le mode test désactivé, la mention “SPECIMEN” ne sera plus présente et tes factures marquées comme payées s’ajouteront automatiquement à ton livre des recettes.
                          <br><br>Prends le temps de bien vérifier ton format de numérotation et ton compteur de départ pour bien (re)démarrer ta facturation.`,
            iconUrl: '',
            imageUrl: '/onboarding/guide/steps/test-mode.png',
            videoUrl: '',
            action: {
              text: 'Désactiver le mode test',
              icon: 'toggle_off',
              iconDirection: 'left',
            },
          },
        ],
      },
      {
        title: 'Fais ta comptabilité simplement',
        level: GuideLevelItem.DO_YOUR_ACCOUNTING_EASILY,
        duration: 'Environ 5 min',
        iconUrl: '/onboarding/guide/levels/comptabilite.png',
        steps: [
          {
            isDisplayable: !!rootGetters['company/isEI'],
            step: GuideStepItem.CONFIGURE_YOUR_CONTRIBUTION_RATES,
            title: 'Configure tes taux de cotisations',
            iconUrl: '',
            content: `Abby va te faire gagner un temps fou en faisant ta comptabilité automatiquement. Tu peux décider de te baser sur ton livre des recettes (par défaut) ou ton compte bancaire. (+ articles)
                          <br><br>Pour être cohérent dans tes estimations de cotisations sociales, configure correctement tes taux de cotisations. Si tu ne les connais pas précisément tu peux toujours aller vérifier sur ta dernière déclaration sur le site de l’Urssaf.`,
            imageUrl: '/onboarding/guide/steps/config-cotisations.png',
            videoUrl: '',
            action: {
              text: 'Configurer mes taux',
              icon: 'settings',
              iconDirection: 'left',
            },
          },
          {
            isDisplayable: !!rootGetters['company/company']?.hasVat,
            step: GuideStepItem.CONFIGURE_YOUR_VAT,
            title: 'Configure ta TVA sur Abby',
            iconUrl: '',
            content: 'Tu vas gagner un temps précieux car Abby te permet de calculer automatiquement ta TVA collectée et déductible (bientôt). Ainsi tu pourras télé-déclarer ta TVA en un clic depuis ici (bientôt).',
            imageUrl: '/onboarding/guide/steps/tva.png',
            videoUrl: '',
            action: {
              text: 'Configurer ma TVA',
              icon: 'settings',
              iconDirection: 'left',
            },
          },
          {
            isDisplayable: !!rootGetters['company/isEI'],
            step: GuideStepItem.ESTIMATE_YOUR_SOCIAL_SECURITY_CONTRIBUTIONS,
            title: 'Fais une estimation de tes cotisations sociales',
            iconUrl: '',
            content: `À tout moment tu peux faire une estimation de tes cotisations sociales. Si tu as déjà réalisé du chiffre d’affaires alors Abby le récupère par défaut pour t’aider à faire tes calculs rapidement. 
                          <br>Dans tous les cas tu peux faire tes propres estimations en modifiant le montant de ton chiffre d’affaires.`,
            imageUrl: '/onboarding/guide/steps/estimation-cotisations.png',
            videoUrl: '',
            action: {
              text: 'Faire une estimation',
              icon: 'visibility',
              iconDirection: 'left',
            },
          },
          {
            isDisplayable: true,
            step: GuideStepItem.CONNECT_YOUR_BANK_ACCOUNT,
            title: 'Connecte ton compte bancaire',
            iconUrl: '',
            content: `Tu veux avoir une visibilité accrue sur tes dépenses et revenus ? Synchronise ton compte bancaire sur Abby. Tu verras toutes tes transactions bancaires que tu pourras catégoriser pour suivre parfaitement l’évolution de ta trésorerie.
                <br><br>Abby peut se connecter à plus de 300 banques. Tes données sont sécuriser et le protocole de connexion utilisé ne permet en aucun cas de pouvoir effectuer des paiements, prélèvements ou virement depuis Abby et tu seras le seul à pouvoir accéder à tes données.
                <br><br>Tu peux décider de baser ta comptabilité sur ton compte bancaire. Par défaut ta comptabilité est basée sur ton livre des recettes.`,
            imageUrl: '/onboarding/guide/steps/connexion-bancaire.png',
            videoUrl: '',
            action: {
              text: 'Connecter ma banque',
              icon: 'sensors',
              iconDirection: 'left',
            },
          },
          {
            isDisplayable: true,
            step: GuideStepItem.ANNOTATE_YOUR_FIRST_TRANSACTION,
            title: 'Annote ta première transaction',
            iconUrl: '',
            content: `Catégorise tes dépenses et revenus facilement. Cela te permettra d’avoir une meilleur visibilité sur ta trésorerie pour prendre de meilleures décisions.
                <br><br>Abby te suggère des catégories automatiquement grâce à l’intelligence artificielle. Si tu as l’habitude d’annoter un type de transaction avec une catégorie, alors Abby la catégorisera automatiquement à l’avenir.`,
            imageUrl: '/onboarding/guide/steps/annoter.png',
            videoUrl: '',
            action: {
              text: 'Annoter une transaction',
              icon: 'announcement',
              iconDirection: 'left',
            },
          },
          {
            isDisplayable: true,
            step: GuideStepItem.ADD_AN_ENTRY_TO_YOUR_PURCHASES_BOOK,
            title: 'Ajoute une entrée dans ton livre des achats',
            iconUrl: '',
            content: `Si tu dois entretenir un livre d’achats pour ton activité, pas de panique. Avec Abby tu vas pouvoir répertorier tes dépenses liées à ton activité en un seul et même endroit.
                <br><br>Tu pourras également associer tes documents à tes entrées pour pouvoir retrouver une facture d’achat à n’importe quel moment.`,
            imageUrl: '/onboarding/guide/steps/livre-achats.png',
            videoUrl: '',
            action: {
              text: 'Ajouter un achat',
              icon: 'add',
              iconDirection: 'left',
            },
          },
          {
            isDisplayable: !!rootGetters['company/isEI'],
            step: GuideStepItem.CONNECT_YOUR_URSSAF_ACCOUNT,
            title: 'Connecte ton compte Urssaf',
            iconUrl: '',
            content: `Ici, ta comptabilité se fait automatiquement. Connecte ton compte Urssaf à Abby pour déclarer et payer tes cotisations en quelques clics.
                <br><br>Abby est partenaire certifié Urssaf. À tout moment tu pourras vérifier ou modifier tes déclarations sur le site officiel de l’Urssaf.`,
            imageUrl: '/onboarding/guide/steps/urssaf.png',
            videoUrl: '',
            action: {
              text: 'Connecter mon compte',
              icon: 'sensors',
              iconDirection: 'left',
            },
          },
        ],
      },
      {
        title: 'Organise toi facilement',
        level: GuideLevelItem.GET_ORGANIZE_EASILY,
        duration: 'Environ 2 min',
        iconUrl: '/onboarding/guide/levels/organisation.png',
        steps: [
          {
            step: GuideStepItem.CREATE_YOUR_FIRST_TASK,
            title: 'Crée ta première tâche',
            iconUrl: '',
            content: 'Tu peux gérer tes tâches simplement avec Abby. Tu recevras un e-mail de notification pour te rappeler tes tâches en retard et du jour.' +
            // @ts-ignore
            // eslint-disable-next-line no-undef
                    `<br><br>Associe tes tâches à tes ${process.browser && xpm?.opportunityTypeIsSet?.() ? xpm.opportunityWordingFor('plural') : 'une carte'} pour avoir enfin tes rappels, tes factures et le suivi de tes clients au même endroit.`,
            imageUrl: '/onboarding/guide/steps/tache.png',
            videoUrl: '',
            action: {
              text: 'Créer une tâche',
              icon: 'add',
              iconDirection: 'left',
            },
          },
          {
            step: GuideStepItem.CREATE_CARD_IN_THE_TRACKING_TABLE,
            // @ts-ignore
            // eslint-disable-next-line no-undef
            title: process.browser && xpm?.opportunityTypeIsSet?.() ? xpm.opportunityWordingFor('guideStepTitle') : 'Crée une carte dans le tableau de suvi',
            iconUrl: '',
            content: `Si tu es quelqu’un d’organisé, tu devrais apprécier de pouvoir suivre tes clients, ta facturation et ta comptabilité au même endroit !
                  <br><br>Tu peux créer le tableau de suivi qui te ressemble en ajoutant ou modifiant tes colonnes. Tous tes documents, tâches, notes et suivi du temps enfin centralisés.`,
            imageUrl: '/onboarding/guide/steps/opportunite.png',
            videoUrl: '',
            action: {
              // @ts-ignore
              // eslint-disable-next-line no-undef
              text: process.browser && xpm?.opportunityTypeIsSet?.() ? xpm.opportunityWordingFor('create') : 'Crée une carte',
              icon: 'add',
              iconDirection: 'left',
            },
          },
          {
            step: GuideStepItem.CREATE_YOUR_FIRST_TIME_TRACKING,
            title: 'Crée ton premier suivi du temps',
            iconUrl: '',
            content: `Adepte de la maîtrise de ton temps ? Suis le avec Abby et affecte lui une valeur financière. Facture en un clic tes clients après un projet ou en fin de mois.
                    <br><br>Pratique pour savoir si tu es rentable, ou si d’habitude tu enregistre tes heures travailler sur Excel, ton agenda ou sur papier. ` +
            // @ts-ignore
            // eslint-disable-next-line no-undef
                    `<br><br>Associe ton temps à ${process.browser && xpm?.opportunityTypeIsSet?.() ? xpm.opportunityWordingFor('guideStepContentSingular') : 'une carte'} pour tout avoir au même endroit !`,
            imageUrl: '/onboarding/guide/steps/suivi-du-temps.png',
            videoUrl: '',
            action: {
              text: 'Suivre mon temps',
              icon: 'add',
              iconDirection: 'left',
            },
          },
        ],
      },
    ]
    return (config as any)
      .map((levelConfig: GuideLevel) => ({
        ...levelConfig,
        steps: levelConfig.steps.filter((step) => {
          return step?.isDisplayable === undefined ? true : step.isDisplayable
        }).map(s => ({
          ...s,
          isDisplayable: true,
          completedAt: state.guideSteps?.find(_s => _s.step === s.step)?.completedAt,
        })),
      }))
  },
  persona: state => state.persona,
  levelToOpen: state => state.levelToOpen,
}

export const mutations: MutationTree<GuideState> = {
  SET_GUIDE_CONFIG (state, guideConfig: IGuideLevel[]) {
    state.guideConfig = guideConfig
  },
  SET_GUIDE_STEPS (state, guideSteps: IGuideStep[]) {
    state.guideSteps = guideSteps
  },
  SET_PERSONA (state, persona: ReadPersona) {
    state.persona = persona
  },
  SET_LEVEL_TO_OPEN (state, value: GuideLevelItem | null) {
    state.levelToOpen = value
  },
  SET_MODAL_KEY (state, { key, value }: { key: keyof GuideState['modal'], value: boolean }) {
    state.modal[key] = value
  },
  RESET (_currentState: GuideState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<GuideState, RootState> = {
  async fetchGuideSteps ({ commit, getters }) {
    try {
      commit('SET_GUIDE_CONFIG', getters.guideConfig)
      const results = await this.$api.guideStep.getAll()
      commit('SET_GUIDE_STEPS', results)
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  },
  async fetchPersona ({ commit }) {
    try {
      const persona = await this.$api.persona.get()
      if (!persona) { return }
      delete persona.__v
      commit('SET_PERSONA', persona)
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  },
  async updatePersona ({ commit }, payload: CreatePersona) {
    try {
      const persona = await this.$api.persona.update(payload)
      delete persona.__v
      commit('SET_PERSONA', persona)
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  },
  async completeStep ({ commit, state }, payload: { step: GuideStepItem, skip?: boolean}) {
    try {
      if (this.$help.hasCompleted(payload.step)) { return }
      const result = await this.$api.guideStep.completeStep(payload.step, { skip: payload.skip })
      commit('SET_GUIDE_STEPS', [
        ...state.guideSteps,
        result,
      ])
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  },
  setLevelToOpen ({ commit }, value: GuideLevelItem | null) {
    commit('SET_LEVEL_TO_OPEN', value)
  },
  closeDeactivateBillingTestModeModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'deactivateBillingTestModeModal', value: false })
  },
  closeUrssafDeclarationModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'urssafDeclarationModal', value: false })
  },
  closeBankSynchroModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'bankSynchroModal', value: false })
  },
  closeOnlinePaymentModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'onlinePaymentModal', value: false })
  },
  closeImmediateAdvanceModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'immediateAdvanceModal', value: false })
  },
  openDeactivateBillingTestModeModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'deactivateBillingTestModeModal', value: true })
  },
  openUrssafDeclarationModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'urssafDeclarationModal', value: true })
  },
  openBankSynchroModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'bankSynchroModal', value: true })
  },
  openOnlinePaymentModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'onlinePaymentModal', value: true })
  },
  openImmediateAdvanceModal ({ commit }) {
    commit('SET_MODAL_KEY', { key: 'immediateAdvanceModal', value: true })
  },
}
