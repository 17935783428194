import { ActionTree, GetterTree, MutationTree } from 'vuex'
import type { IExportModalParams } from '@abby/core-legacy'
import { RootState } from '~/store/index'

export interface ExportsState {
  exportModalParams: IExportModalParams | null;
}

export const state = (): ExportsState => ({
  exportModalParams: null,
})

export const getters: GetterTree<ExportsState, RootState> = {
  exportModalParams: state => state.exportModalParams,
}

export const mutations: MutationTree<ExportsState> = {
  SET_EXPORT_MODAL_OPENED (state, value: IExportModalParams | null) {
    state.exportModalParams = value
  },
  RESET (_currentState: ExportsState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<ExportsState, RootState> = {
  setExportModalOpened ({ commit }, value: IExportModalParams | null) {
    commit('SET_EXPORT_MODAL_OPENED', value)
  },
}
