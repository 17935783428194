
import { Component, Getter, PropSync, Vue } from 'nuxt-property-decorator'
import {
  AbbyPlans,
  PlansConfig,
  SegmentEvent,
  StripeProductFrequency,
  PersonaFeature,
  IBillingConfiguration, ReadPersona, CampaignType, RegisteredType, ABGroupPricing,
} from '@abby/core-legacy'
import AbbyPlanChip from '~/components-legacy/chips/AbbyPlanChip.vue'
import { IAuthUser } from '~/store/auth'
@Component({
  components: { AbbyPlanChip },
})
export default class AbbyNewPricingPlan extends Vue {
  @Getter('billingConfiguration/billingConfiguration')
  billingConfiguration!: IBillingConfiguration;

  @Getter('guide/persona') persona!: ReadPersona | null;

  @Getter('auth/user') user!: IAuthUser

  @PropSync('frequency')
  _frequency!: StripeProductFrequency;

  get StripeProductFrequency () {
    return StripeProductFrequency
  }

  get hasDouble2Offer () {
    return !this.$campaignsManager.isDisplayable(CampaignType.DOUBLE_2) && (this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_FREE || this.$planManager.isTrial())
  }

  get AbbyPlans () {
    return AbbyPlans
  }

  get taxText () {
    // expérimentation A --> après le 29/11/2023 groupe avec le prix HT
    if (this.$planManager.abGroupPricing() === ABGroupPricing.GROUP_A) {
      return 'mois ht'
    }
    // expérimentation par défault --> avant le 29/11/2023 groupe avec le prix TTC
    return 'mois ttc'
  }

  selectedPlan: AbbyPlans = AbbyPlans.ABBY_PRO

  get plansToDisplay () {
    if (this.$vuetify.breakpoint.smAndDown) {
      return this.plans.filter(p => p.plan === this.selectedPlan)
    }
    return this.plans
  }

  get promotionRate () {
    if (this.isPromotionDay) {
      return 0.65
    } else {
      return 1
    }
  }

  priceAmount (plan: any) {
    if (plan.plan === AbbyPlans.ABBY_BUSINESS) {
      return this.$amount.formatCents(plan.pricing[this._frequency].amountByMonth).text
    } else if (this._frequency === StripeProductFrequency.YEAR) {
      return this.$amount.formatCents(plan.pricing[this._frequency].amountByMonth * this.promotionRate).text
    } else if (this._frequency === StripeProductFrequency.MONTH) {
      return this.$amount.formatCents(plan.pricing[this._frequency].amountByMonth * this.promotionRate).text
    }
    return this.$amount.formatCents(plan.pricing[this._frequency].amountByMonth).text
  }

  get isPromotionDay () {
    const isSubProYearly = this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_PRO && !this.$planManager.isTrial() && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR
    const isPromotionDay = this.$dayjs().isBetween('2024-01-02', '2025-03-31', 'day', '[]')

    return !isSubProYearly && isPromotionDay
  }

  get hasTiersPrestation () {
    return this.persona?.interestedInFeatures?.includes(PersonaFeature.IMMEDIATE_ADVANCE_API) || this.billingConfiguration?.tiersPrestationActivated
  }

  get SAPProPlan () {
    return {
      title: 'Offre SAP',
      subtitle: 'Tous les outils pour facturer avec l\'avance immédiate',
      plan: AbbyPlans.ABBY_PRO,
      software: [
        {
          text: 'L\'offre Start',
        },
        {
          text: 'Avance de crédit d\'impôts (API tiers de prestation)',
          highlighted: true,
        },
        {
          text: 'Déclaration NOVA et attestation fiscale en un clic',
          highlighted: true,
        },
        {
          text: 'Synchronisation bancaire',
        },
        {
          text: 'S’assurer d’être payé dans les temps : Relance d’impayés',
        },
        {
          text: 'Signature des docs. en ligne',
        },
        {
          text: 'Outils de productivité en illimité (suivi du temps, ventes, clients..)',
        },
        {
          text: 'Intégrations aux applications',
        },
        {
          text: 'Facturation récurrente auto.',
        },
        {
          text: 'Facturation, gestion et déclaration TVA',
        },
        {
          text: 'Devises et langues étrangères',
        },
      ],
      support: [
        {
          text: 'Chat et rendez-vous en visio avec votre conseiller',
        },
        {
          text: 'Résolution des problèmes prioritaire',
        },
      ],
      community: [
        {
          text: 'Chat en direct avec les membres de l’équipe Abby',
        },
        {
          text: 'Réductions plus avantageuses sur nos produits et services partenaires',
        },
        {
          text: 'Marque distinctive Pro',
        },
      ],
    }
  }

  get plans () {
    return [{
      title: 'Basique',
      callToAction: 'Je m\'inscris',
      subtitle: 'Facturez vos clients simplement',
      condition: 'Sans engagement',
      plan: AbbyPlans.ABBY_FREE,
      free: true,
      pricing: {
        [StripeProductFrequency.MONTH]: { text: 'Gratuit' },
        [StripeProductFrequency.YEAR]: { text: 'Gratuit' },
      },
      software: [
        {
          text: 'Devis & factures illimités',
        },
        {
          text: 'Comptabilité conforme',
        },
        {
          text: 'Estimation CA et cotisations Urssaf',
        },
        {
          text: 'Paiement en ligne',
        },
      ],
      support: [
        {
          text: 'Support basique',
        },
      ],
      community: [
        {
          text: 'Accès libre',
        },
      ],
    }, {
      title: 'Commencer',
      callToAction: 'Essayer gratuitement',
      subtitle: 'Mettez en valeur votre entreprise',
      condition: '14 jours offerts, sans engagement',
      plan: AbbyPlans.ABBY_START,
      pricing: {
        ...PlansConfig[AbbyPlans.ABBY_START].pricing[this.$planManager.abGroupPricing()],
      },
      software: [
        {
          text: 'L\'offre Basique',
        },
        {
          text: 'Documents personnalisés',
        },
        {
          text: 'Retirer le logo Abby des factures',
        },
        {
          text: 'Facturation avec acompte',
        },
        {
          text: 'Déclaration et paiement Urssaf',
        },
        {
          text: 'Rappel Urssaf, Impôts et CFE',
        },
        {
          text: 'Mode archive des documents',
        },
      ],
      support: [
        {
          text: 'Chat avec des conseillers',
        },
      ],
      community: [
        {
          text: 'Accès aux tableaux des suggestions et à la roadmap publique',
        },
        {
          text: 'Marque distinctive Start',
        },
      ],
    }, {
      title: 'Maitriser',
      callToAction: 'Essayer gratuitement',
      subtitle: 'Travaillez l’esprit libre avec un environnement tout-en-un',
      condition: '14 jours offerts, sans engagement',
      popular: true,
      plan: AbbyPlans.ABBY_PRO,
      pricing: {
        ...PlansConfig[AbbyPlans.ABBY_PRO].pricing[this.$planManager.abGroupPricing()],
      },
      software: [
        {
          text: 'L\'offre Start',
        },
        {
          text: 'Synchronisation bancaire',
        },
        {
          text: 'S’assurer d’être payé dans les temps : Relance d’impayés',
        },
        {
          text: 'Signature des docs. en ligne',
        },
        {
          text: 'Outils de productivité en illimité (suivi du temps, ventes, clients..)',
        },
        {
          text: 'Intégrations aux applications',
        },
        {
          text: 'Facturation récurrente auto.',
        },
        {
          text: 'Facturation, gestion et déclaration TVA',
        },
        {
          text: 'Devises et langues étrangères',
        },
        {
          text: 'Avance de crédit d\'impôts (API tiers de prestation)',
        },
        {
          text: 'Déclaration NOVA et attestation fiscale en un clic',
        },
      ],
      support: [
        {
          text: 'Chat et rendez-vous en visio avec votre conseiller',
        },
        {
          text: 'Résolution des problèmes prioritaire',
        },
      ],
      community: [
        {
          text: 'Chat en direct avec les membres de l’équipe Abby',
        },
        {
          text: 'Réductions plus avantageuses sur nos produits et services partenaires',
        },
        {
          text: 'Marque distinctive Pro',
        },
      ],
      ...this.hasTiersPrestation
        ? this.SAPProPlan
        : {},
    }, {
      title: 'Aller plus loin',
      callToAction: 'Essayer gratuitement',
      subtitle: 'Faites vous accompagner pour la croissance de votre entreprise',
      condition: '14 jours offerts, sans engagement',
      plan: AbbyPlans.ABBY_BUSINESS,
      pricing: {
        ...PlansConfig[AbbyPlans.ABBY_BUSINESS].pricing[this.$planManager.abGroupPricing()],
      },
      software: [
        {
          text: 'L\'offre Pro',
        },
        {
          text: 'Tableau de bord statistiques avancé',
        },
        {
          text: 'Rapprochement bancaire automatique',
        },
        {
          text: 'Export FEC',
        },
        {
          text: 'Gestion des stocks',
        },
        {
          text: 'Prévision de trésorerie',
        },
        {
          text: 'Télé-déclaration de la TVA',
        },
        {
          text: 'Multi tableau de suivi',
        },
        {
          text: 'Accès expert-comptable',
        },
        {
          text: 'Gestion multi-utilisateurs',
        },
      ],
      support: [
        {
          text: 'Conseiller dédié',
        },
        {
          text: 'Aide à l’installation (passage d’un autre logiciel à Abby)',
        },
        {
          text: 'Temps de réponse garanti',
        },
      ],
      community: [
        {
          text: 'Newsletter privée',
        },
        {
          text: 'Augmentation des chances de gagner des goodies Abby',
        },
        {
          text: 'Marque distinctive Business',
        },
      ],
    }]
  }

  setFrequency (value: StripeProductFrequency) {
    this.$ap.segmentTrack(SegmentEvent.PLANS_FREQUENCY_CHANGED, {
      frequency: value,
    })
    this.$emit('update:frequency', value)
  }
}
