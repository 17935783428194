import { render, staticRenderFns } from "./UserNavigationMenu.vue?vue&type=template&id=807c32f8&scoped=true"
import script from "./UserNavigationMenu.vue?vue&type=script&lang=ts"
export * from "./UserNavigationMenu.vue?vue&type=script&lang=ts"
import style0 from "./UserNavigationMenu.vue?vue&type=style&index=0&id=807c32f8&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../common/temp/default/node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_prettier@2.8.8_vue-template-compiler@2.7.16_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "807c32f8",
  null
  
)

export default component.exports