import { render, staticRenderFns } from "./CustomerItemLabel.vue?vue&type=template&id=90321966&scoped=true"
import script from "./CustomerItemLabel.vue?vue&type=script&setup=true&lang=ts"
export * from "./CustomerItemLabel.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../common/temp/default/node_modules/.pnpm/vue-loader@15.11.1_cache-loader@4.1.0_css-loader@5.2.7_prettier@2.8.8_vue-template-compiler@2.7.16_webpack@4.47.0/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90321966",
  null
  
)

export default component.exports