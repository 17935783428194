import currencyJs from 'currency.js';
import { Vat, VatCode } from '..';
import { CurrencyCode, CurrencySymbol } from '../enums';

/*
Use fixNumber function to correct the floating point precision error in JavaScript
In order to fix issues where:

0.3 - 0.1 => 0.199999999
0.57 * 100 => 56.99999999
0.0003 - 0.0001 => 0.00019999999
You can do something like:

const fixNumber = num => Number(num.toPrecision(15));
Few examples:

fixNumber(0.3 - 0.1) => 0.2
fixNumber(0.0003 - 0.0001) => 0.0002
fixNumber(0.57 * 100) => 57

See https://stackoverflow.com/questions/47634766/algorithm-to-correct-the-floating-point-precision-error-in-javascript
*/
export const fixNumber = (number: number) => Number(number.toPrecision(15));

export const currencyPattern = {
  [CurrencyCode.EUR]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.EUR, pattern: '# !', negativePattern: '- # !',
  },
  [CurrencyCode.USD]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.USD, pattern: '!#', negativePattern: '- !#',
  },
  [CurrencyCode.GBP]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.GBP, pattern: '!#', negativePattern: '- !#',
  },
  [CurrencyCode.CHF]: {
    precision: 2, decimal: '.', separator: ' ', symbol: CurrencySymbol.CHF, pattern: '#!', negativePattern: '- # !',
  },
  [CurrencyCode.JPY]: {
    precision: 0, decimal: '.', separator: ',', symbol: CurrencySymbol.JPY, pattern: '!#', negativePattern: '- !#',
  },
  [CurrencyCode.CNH]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.CNH, pattern: '!#', negativePattern: '- !#',
  },
  [CurrencyCode.CZK]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.CZK, pattern: '# !', negativePattern: '- # !',
  },
  [CurrencyCode.DKK]: {
    precision: 2, decimal: ',', separator: '.', symbol: CurrencySymbol.DKK, pattern: '# !', negativePattern: '- # !',
  },
  [CurrencyCode.BGN]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.BGN, pattern: '# !', negativePattern: '- # !',
  },
  [CurrencyCode.PLN]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.PLN, pattern: '# !', negativePattern: '- # !',
  },
  [CurrencyCode.HUF]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.HUF, pattern: '# !', negativePattern: '- # !',
  },
  [CurrencyCode.RON]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.RON, pattern: '# !', negativePattern: '- # !',
  },
  [CurrencyCode.SEK]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.SEK, pattern: '# !', negativePattern: '- # !',
  },
  [CurrencyCode.NOK]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.NOK, pattern: '# !', negativePattern: '- # !',
  },
  [CurrencyCode.TRY]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.TRY, pattern: '# !', negativePattern: '- # !',
  },
  [CurrencyCode.BRL]: {
    precision: 2, decimal: ',', separator: ' ', symbol: CurrencySymbol.BRL, pattern: '# !', negativePattern: '- # !',
  },
  [CurrencyCode.HKD]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.HKD, pattern: '!#', negativePattern: '- !#',
  },
  [CurrencyCode.ILS]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.ILS, pattern: '!#', negativePattern: '- !#',
  },
  [CurrencyCode.INR]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.INR, pattern: '!#', negativePattern: '- !#',
  },
  [CurrencyCode.KRW]: {
    precision: 0, decimal: '.', separator: ',', symbol: CurrencySymbol.KRW, pattern: '!#', negativePattern: '- !#',
  },
  [CurrencyCode.MXN]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.MXN, pattern: '!#', negativePattern: '- !#',
  },
  [CurrencyCode.AUD]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.AUD, pattern: '!#', negativePattern: '- !#',
  },
  [CurrencyCode.CAD]: {
    precision: 2, decimal: '.', separator: ',', symbol: CurrencySymbol.CAD, pattern: '!#', negativePattern: '- !#',
  },
};

export const roundDecimal = (_number: number, _precision?: number) => {
  const number = fixNumber(_number);
  const precision = _precision == null ? 0 : Math.min(Number(_precision), 292);
  if (precision && Number.isFinite(number)) {
    // Shift with exponential notation to avoid floating-point issues.
    // See [MDN](https://mdn.io/round#Examples) for more details.
    let pair = (`${String(number)}e`).split('e');
    const value = Math.round(Number(`${pair[0]}e${+pair[1] + precision}`));

    pair = (`${String(value)}e`).split('e');

    return +(`${pair[0]}e${+pair[1] - precision}`);
  }
  return Math.round(number);
};

interface PricesProps {
  withoutTaxes: number;
  vatAmount: number;
  withTaxes: number;
  taxesIncluded: boolean;
}

interface PricesSnapshot {
  withoutTaxes: number;
  vatAmount: number;
  withTaxes: number;
  taxesIncluded: boolean;
}

export class Prices {
  #withoutTaxes: number;
  #vatAmount: number;
  #withTaxes: number;
  #taxesIncluded: boolean;

  get withoutTaxes(): number {
    return this.#withoutTaxes;
  }

  get vatAmount(): number {
    return this.#vatAmount;
  }

  get withTaxes(): number {
    return this.#withTaxes;
  }

  get taxesIncluded(): boolean {
    return this.#taxesIncluded;
  }

  private constructor(props: PricesProps) {
    this.#withoutTaxes = props.withoutTaxes;
    this.#vatAmount = props.vatAmount;
    this.#withTaxes = props.withTaxes;
    this.#taxesIncluded = props.taxesIncluded || false;
  }

  public static create(props: PricesProps): Prices {
    return new Prices(props);
  }

  public static calculateFromCents(amount: number, taxesIncluded = false, vatCode: VatCode): PricesProps {
    if (vatCode === VatCode.FR_00HT && !taxesIncluded) {
      return {
        withoutTaxes: amount,
        withTaxes: amount,
        vatAmount: 0,
        taxesIncluded: false,
      };
    }

    const vatItem = Vat.getVatItem(vatCode);

    if (vatItem == null) {
      throw new Error(`Vat code <${vatCode}> is not available.`);
    }

    if (vatItem.percentage === 0) {
      return {
        withoutTaxes: amount,
        withTaxes: amount,
        vatAmount: 0,
        taxesIncluded: false,
      };
    }

    const precision = this.getPrecisionFromCents(amount);
    const vatRate = 1 + (vatItem.percentage / 100);
    const withoutTaxes = taxesIncluded ? roundDecimal(amount / vatRate, precision) : amount;
    const withTaxes = taxesIncluded ? amount : roundDecimal(amount * vatRate, precision);

    return {
      withoutTaxes,
      withTaxes,
      vatAmount: fixNumber(withTaxes - withoutTaxes),
      taxesIncluded,
    };
  }

  private static getPrecisionFromCents(amountInCents: number): number {
    const amount = fixNumber(amountInCents / 100);
    const amountAsString: string = amount.toString();
    const indexOfDecimalDot: number = amountAsString.indexOf('.');
    if (indexOfDecimalDot === -1) {
      return 0;
    }
    return amountAsString.length - indexOfDecimalDot - 1 >= 2 ? amountAsString.length - indexOfDecimalDot - 1 : 2;
  }

  private static format(amountInCents: number, showDecimals: boolean, currency: CurrencyCode = CurrencyCode.EUR): string {
    const pattern = currencyPattern[currency];
    const precisionToAdd = showDecimals ? Prices.getPrecisionFromCents(fixNumber(amountInCents * (10 ** pattern.precision))) : 0;
    const currencyValue = currencyJs(amountInCents * (10 ** precisionToAdd), { fromCents: true, ...pattern, precision: pattern.precision + precisionToAdd });
    return currencyValue.format();
  }

  public formatWithTaxes(): string {
    return Prices.format(this.withTaxes, true);
  }

  public formatWithoutTaxes(): string {
    return Prices.format(this.withoutTaxes, true);
  }

  public snapshot(): PricesSnapshot {
    return {
      withoutTaxes: this.#withoutTaxes,
      vatAmount: this.#vatAmount,
      withTaxes: this.#withTaxes,
      taxesIncluded: this.#taxesIncluded,
    };
  }
}