import { CreateContact } from '@abby/shared'
import { useContactRepository } from '~/logic/contexts/client/infrastructure/repository/contact.repository'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'
import { useBusManager } from '~/composables/shared/manager/useBusManager'

export const useCreateContact = () => {
  const contactRepository = useContactRepository()
  const alertManager = useAlertManager()
  const busManager = useBusManager()

  return async (contact: CreateContact) => {
    const newContact = await contactRepository.createContact(contact)
    alertManager.success('Le contact a bien été créé')
    busManager.emit('contactCreated', newContact)
    return newContact
  }
}
