import { Context } from '@nuxt/types'
import {
  Analytics,
  Auth,
  Billing,
  Bank,
  Subscription,
  Webinar,
  AddressResource as Address,
  Product,
  Contact,
  Company,
  Organization,
  Opportunity,
  TierPrestation,
  Customer,
} from '@abby/shared'

const resources = {
  analytics: Analytics,
  auth: Auth,
  billing: Billing,
  company: Company,
  customer: Customer,
  address: Address,
  contact: Contact,
  organization: Organization,
  opportunity: Opportunity,
  subscription: Subscription,
  tierPrestation: TierPrestation,
  product: Product,
  webinar: Webinar,
  bank: Bank,
}

export default (context: Context, inject: any) => {
  const axiosInstance = context.$axios
  const wrapAxios = (instance: any) => ({
    ...instance,
    async $get (...params: any[]) {
      const { data } = await instance.get(...params)
      return data
    },
    async $put (...params: any[]) {
      const { data } = await instance.put(...params)
      return data
    },
    async $post (...params: any[]) {
      const { data } = await instance.post(...params)
      return data
    },
    async $patch (...params: any[]) {
      const { data } = await instance.patch(...params)
      return data
    },
    async $delete (...params: any[]) {
      const { data } = await instance.delete(...params)
      return data
    },
  })

  const _resources = Object.entries(resources)
    .reduce((acc, [name, value]: [string, any]) => ({
      ...acc,
      // eslint-disable-next-line new-cap
      [name]: new value(wrapAxios(axiosInstance)),
    }), {})

  return inject('backend', {
    ..._resources,
  })
}

type ApiMethods = {
  [K in keyof typeof resources]: InstanceType<typeof resources[K]>;
};

declare module 'vue/types/vue' {
  interface Vue {
    $backend: ApiMethods;
  }
}

declare module '@nuxt/types' {
  interface NuxtAppOptions {
    $backend: ApiMethods;
  }
  interface Context {
    $backend: ApiMethods;
  }
}

declare module 'vuex' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface Store<S> {
    $backend: ApiMethods;
  }
}
