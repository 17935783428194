
import { Component, Prop, Vue } from 'nuxt-property-decorator'
import { NoWithTrialFeatures, PlanFeature } from '@abby/core-legacy'
import { PaymentCallbacks } from '~/store/payment'
import AbbyPlanChip from '~/components-legacy/chips/AbbyPlanChip.vue'

@Component({
  components: { AbbyPlanChip },
})
export default class AbbyPlanButton extends Vue {
  @Prop({ required: true })
  feature!: PlanFeature

  @Prop({ default: false })
  noText!: boolean

  @Prop({ default: false })
  small!: boolean

  @Prop({ default: true })
  large!: boolean

  @Prop({ default: false })
  openPlansDirectly!: boolean

  @Prop()
  action!: () => Promise<void> | void

  @Prop()
  callbacks!: PaymentCallbacks

  get hasAccessTo () {
    return this.$planManager.hasAccessTo(this.feature)
  }

  get isTrialExpired () {
    return this.$planManager.isTrialExpired()
  }

  get noTrialForThisFeature () {
    return this.feature && NoWithTrialFeatures.includes(this.feature)
  }

  get text () {
    if (this.noText) { return '' }
    if (this.$planManager.isLockedBecauseOfFailedPayment()) {
      return 'Payer mon abonnement'
    }
    return this.noTrialForThisFeature || this.isTrialExpired ? 'Obtenir' : 'Essayer gratuitement'
  }

  async clicked () {
    this.$emit('clicked', this.feature)
    if (this.action) {
      await this.action?.()
    } else if (this.$planManager.isLockedBecauseOfFailedPayment()) {
      window.open(this.$planManager.failedInformation()?.failedInvoiceUrl || '', '_blank')
    } else {
      await this.$planManager.openAbbyPlansModal({
        feature: this.feature,
        callbacks: this.callbacks,
        openPlan: this.openPlansDirectly,
      })
    }
  }
}
