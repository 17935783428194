
import { Component, Vue, Prop } from 'nuxt-property-decorator'

@Component
export default class AmountLabel extends Vue {
  @Prop({ default: 0 })
  amount!: number

  @Prop({ default: false })
  round!: boolean

  @Prop({ default: true })
  currency!: boolean

  @Prop({ default: 'decimals' })
  type!: 'decimals' | 'cents'

  @Prop({ default: 'normal' })
  size!: 'normal' | 'small' | 'large'

  @Prop({ default: false })
  defaultColor!: boolean | string

  get _defaultColor () {
    return this.defaultColor || typeof this.defaultColor === 'string'
  }

  get formatAmount () {
    if (this.type === 'decimals') {
      return this.$amount.formatDecimals(this.amount)
    } else {
      return this.$amount.formatCents(this.amount)
    }
  }
}
