import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

const VUEX_PROPERTIES = ['state', 'getters', 'actions', 'mutations']

let store = {};

(function updateModules () {
  store = normalizeRoot(require('../store/index.ts'), 'store/index.ts')

  // If store is an exported method = classic mode (deprecated)

  // Enforce store modules
  store.modules = store.modules || {}

  resolveStoreModules(require('../store/abbyStore.ts'), 'abbyStore.ts')
  resolveStoreModules(require('../store/academy.ts'), 'academy.ts')
  resolveStoreModules(require('../store/accounting.ts'), 'accounting.ts')
  resolveStoreModules(require('../store/accountingAccounts.ts'), 'accountingAccounts.ts')
  resolveStoreModules(require('../store/accountingPage.ts'), 'accountingPage.ts')
  resolveStoreModules(require('../store/accounts.ts'), 'accounts.ts')
  resolveStoreModules(require('../store/acreBoard.ts'), 'acreBoard.ts')
  resolveStoreModules(require('../store/activities.ts'), 'activities.ts')
  resolveStoreModules(require('../store/administration.ts'), 'administration.ts')
  resolveStoreModules(require('../store/alertsManager.ts'), 'alertsManager.ts')
  resolveStoreModules(require('../store/app.ts'), 'app.ts')
  resolveStoreModules(require('../store/application.ts'), 'application.ts')
  resolveStoreModules(require('../store/auth.ts'), 'auth.ts')
  resolveStoreModules(require('../store/bankList.ts'), 'bankList.ts')
  resolveStoreModules(require('../store/billing.ts'), 'billing.ts')
  resolveStoreModules(require('../store/billingConfiguration.ts'), 'billingConfiguration.ts')
  resolveStoreModules(require('../store/billingCreation.ts'), 'billingCreation.ts')
  resolveStoreModules(require('../store/calendly.ts'), 'calendly.ts')
  resolveStoreModules(require('../store/campaigns.ts'), 'campaigns.ts')
  resolveStoreModules(require('../store/cfe.ts'), 'cfe.ts')
  resolveStoreModules(require('../store/clients.ts'), 'clients.ts')
  resolveStoreModules(require('../store/clientsBoard.ts'), 'clientsBoard.ts')
  resolveStoreModules(require('../store/commercialOffers.ts'), 'commercialOffers.ts')
  resolveStoreModules(require('../store/company.ts'), 'company.ts')
  resolveStoreModules(require('../store/confirmManager.ts'), 'confirmManager.ts')
  resolveStoreModules(require('../store/creation.ts'), 'creation.ts')
  resolveStoreModules(require('../store/creationForm.ts'), 'creationForm.ts')
  resolveStoreModules(require('../store/currency.ts'), 'currency.ts')
  resolveStoreModules(require('../store/customerDetails.ts'), 'customerDetails.ts')
  resolveStoreModules(require('../store/customers.ts'), 'customers.ts')
  resolveStoreModules(require('../store/dashboardStatistics.ts'), 'dashboardStatistics.ts')
  resolveStoreModules(require('../store/declarations.ts'), 'declarations.ts')
  resolveStoreModules(require('../store/experience.ts'), 'experience.ts')
  resolveStoreModules(require('../store/exports.ts'), 'exports.ts')
  resolveStoreModules(require('../store/frequency.ts'), 'frequency.ts')
  resolveStoreModules(require('../store/gestion.ts'), 'gestion.ts')
  resolveStoreModules(require('../store/guide.ts'), 'guide.ts')
  resolveStoreModules(require('../store/helpCenter.ts'), 'helpCenter.ts')
  resolveStoreModules(require('../store/incomeBook.ts'), 'incomeBook.ts')
  resolveStoreModules(require('../store/integration.ts'), 'integration.ts')
  resolveStoreModules(require('../store/issue.ts'), 'issue.ts')
  resolveStoreModules(require('../store/landingPage.ts'), 'landingPage.ts')
  resolveStoreModules(require('../store/log.ts'), 'log.ts')
  resolveStoreModules(require('../store/note.ts'), 'note.ts')
  resolveStoreModules(require('../store/nova.ts'), 'nova.ts')
  resolveStoreModules(require('../store/onboarding.ts'), 'onboarding.ts')
  resolveStoreModules(require('../store/opportunity.ts'), 'opportunity.ts')
  resolveStoreModules(require('../store/payment.ts'), 'payment.ts')
  resolveStoreModules(require('../store/productDetails.ts'), 'productDetails.ts')
  resolveStoreModules(require('../store/products.ts'), 'products.ts')
  resolveStoreModules(require('../store/providerDetails.ts'), 'providerDetails.ts')
  resolveStoreModules(require('../store/providers.ts'), 'providers.ts')
  resolveStoreModules(require('../store/purchaseRegister.ts'), 'purchaseRegister.ts')
  resolveStoreModules(require('../store/purchaseRegisterLegacy.ts'), 'purchaseRegisterLegacy.ts')
  resolveStoreModules(require('../store/reasons.ts'), 'reasons.ts')
  resolveStoreModules(require('../store/selection.ts'), 'selection.ts')
  resolveStoreModules(require('../store/signature.ts'), 'signature.ts')
  resolveStoreModules(require('../store/signup.ts'), 'signup.ts')
  resolveStoreModules(require('../store/signup2.ts'), 'signup2.ts')
  resolveStoreModules(require('../store/sponsorship.ts'), 'sponsorship.ts')
  resolveStoreModules(require('../store/start.ts'), 'start.ts')
  resolveStoreModules(require('../store/startGestion.ts'), 'startGestion.ts')
  resolveStoreModules(require('../store/statistics.ts'), 'statistics.ts')
  resolveStoreModules(require('../store/task.ts'), 'task.ts')
  resolveStoreModules(require('../store/taskDetails.ts'), 'taskDetails.ts')
  resolveStoreModules(require('../store/timeTracking.ts'), 'timeTracking.ts')
  resolveStoreModules(require('../store/tracking.ts'), 'tracking.ts')
  resolveStoreModules(require('../store/upsells.ts'), 'upsells.ts')
  resolveStoreModules(require('../store/urssaf.ts'), 'urssaf.ts')
  resolveStoreModules(require('../store/user.ts'), 'user.ts')
  resolveStoreModules(require('../store/vat.ts'), 'vat.ts')
  resolveStoreModules(require('../store/vats.ts'), 'vats.ts')
  resolveStoreModules(require('../store/video.ts'), 'video.ts')
  resolveStoreModules(require('../store/webhook.ts'), 'webhook.ts')
  resolveStoreModules(require('../store/webinar.ts'), 'webinar.ts')

  // If the environment supports hot reloading...
})()

// createStore
export const createStore = store instanceof Function ? store : () => {
  return new Vuex.Store(Object.assign({
    strict: (process.env.NODE_ENV !== 'production')
  }, store))
}

function normalizeRoot (moduleData, filePath) {
  moduleData = moduleData.default || moduleData

  if (moduleData.commit) {
    throw new Error(`[nuxt] ${filePath} should export a method that returns a Vuex instance.`)
  }

  if (typeof moduleData !== 'function') {
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData)
  }
  return normalizeModule(moduleData, filePath)
}

function normalizeModule (moduleData, filePath) {
  if (moduleData.state && typeof moduleData.state !== 'function') {
    console.warn(`'state' should be a method that returns an object in ${filePath}`)

    const state = Object.assign({}, moduleData.state)
    // Avoid TypeError: setting a property that has only a getter when overwriting top level keys
    moduleData = Object.assign({}, moduleData, { state: () => state })
  }
  return moduleData
}

function resolveStoreModules (moduleData, filename) {
  moduleData = moduleData.default || moduleData
  // Remove store src + extension (./foo/index.js -> foo/index)
  const namespace = filename.replace(/\.(js|mjs|ts)$/, '')
  const namespaces = namespace.split('/')
  let moduleName = namespaces[namespaces.length - 1]
  const filePath = `store/${filename}`

  moduleData = moduleName === 'state'
    ? normalizeState(moduleData, filePath)
    : normalizeModule(moduleData, filePath)

  // If src is a known Vuex property
  if (VUEX_PROPERTIES.includes(moduleName)) {
    const property = moduleName
    const propertyStoreModule = getStoreModule(store, namespaces, { isProperty: true })

    // Replace state since it's a function
    mergeProperty(propertyStoreModule, moduleData, property)
    return
  }

  // If file is foo/index.js, it should be saved as foo
  const isIndexModule = (moduleName === 'index')
  if (isIndexModule) {
    namespaces.pop()
    moduleName = namespaces[namespaces.length - 1]
  }

  const storeModule = getStoreModule(store, namespaces)

  for (const property of VUEX_PROPERTIES) {
    mergeProperty(storeModule, moduleData[property], property)
  }

  if (moduleData.namespaced === false) {
    delete storeModule.namespaced
  }
}

function normalizeState (moduleData, filePath) {
  if (typeof moduleData !== 'function') {
    console.warn(`${filePath} should export a method that returns an object`)
    const state = Object.assign({}, moduleData)
    return () => state
  }
  return normalizeModule(moduleData, filePath)
}

function getStoreModule (storeModule, namespaces, { isProperty = false } = {}) {
  // If ./mutations.js
  if (!namespaces.length || (isProperty && namespaces.length === 1)) {
    return storeModule
  }

  const namespace = namespaces.shift()

  storeModule.modules[namespace] = storeModule.modules[namespace] || {}
  storeModule.modules[namespace].namespaced = true
  storeModule.modules[namespace].modules = storeModule.modules[namespace].modules || {}

  return getStoreModule(storeModule.modules[namespace], namespaces, { isProperty })
}

function mergeProperty (storeModule, moduleData, property) {
  if (!moduleData) {
    return
  }

  if (property === 'state') {
    storeModule.state = moduleData || storeModule.state
  } else {
    storeModule[property] = Object.assign({}, storeModule[property], moduleData)
  }
}
