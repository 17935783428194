import { BillingItem } from '~/logic/contexts/billing/domain/entity/billingItem.entity'
import { useBusManager } from '~/composables/shared/manager/useBusManager'
import {
  useAccountingBillingRepository,
} from '~/logic/contexts/billing/infrastructure/repository/accountingBilling.repository'
import { useConfirmManager } from '~/composables/shared/manager/useConfirmManager'

export const useMarkAsUnpaid = () => {
  const busManager = useBusManager()
  const accountingBillingRepository = useAccountingBillingRepository()
  const confirmManager = useConfirmManager()

  return async (billing: BillingItem) => {
    const confirm = await confirmManager.ask({
      title: 'Marquer comme non payé',
      content: `Êtes-vous certain de vouloir marquer le document ${billing.number} comme non payé ?`,
      confirmText: 'Confirmer',
      confirmButton: { depressed: true, color: 'primary' },
    })
    if (!confirm) { return }
    await accountingBillingRepository.markBillingAsUnpaid(billing.id, billing.type)
    busManager.emit('billingUpdated', billing)
  }
}
