
import Vue from 'vue'
import { Component, Getter, Watch } from 'nuxt-property-decorator'

import {
  AbbyAddons,
  AbbyPlans, ABGroupPricing, AddonsConfig,
  CampaignType, getCompleteTotalAmount,
  PlansConfig,
  SegmentEvent,
  StripeProductFrequency,
  StripeProductType,
} from '@abby/core-legacy'
import { confetti } from 'dom-confetti'
import ASDialog from '~/components-legacy/modals/ASDialog.vue'
import Link from '~/components-legacy/utils/Link.vue'
import ChoiceSelect from '~/components-legacy/inputs/ChoiceSelect.vue'
import NewPaymentCard from '~/components-legacy/cards/payment/NewPaymentCard.vue'
import { PaymentState } from '~/store/payment'
import { IAuthUser } from '~/store/auth'
import NewCallToAction from '~/components-legacy/cards/NewCallToAction.vue'

@Component({
  components: {
    NewCallToAction,
    NewPaymentCard,
    ChoiceSelect,
    Link,
    ASDialog,
  },
})
export default class PaymentModal extends Vue {
  close () {
    this.$store.dispatch('payment/closePaymentModal')
    this.displayCEUpsell = true
  }

  @Getter('payment/paymentModal')
  paymentModal!: PaymentState['paymentModal']

  @Getter('auth/user')
  user!: IAuthUser

  frequency: StripeProductFrequency.YEAR | StripeProductFrequency.MONTH = StripeProductFrequency.YEAR
  plan: StripeProductType = StripeProductType.ABBY_PRO
  option: StripeProductType | null = StripeProductType.ABBY_CE

  displayCEUpsell: boolean = false

  showThanks: boolean = false;

  interval: any

  ABVersion: number = 10;

  created () {
    // TODO: À adapter pour toujours afficher le CE sans la variable AB
    this.ABVersion = 10
    this.displayCEUpsell = this.canCEUpsellBeShowed
  }

  get CEPrice () {
    return AddonsConfig[AbbyAddons.ABBY_CE].pricing[this.$planManager.abGroupPricing()][this.frequency].amountByMonth
  }

  @Watch('paymentModal.opened')
  handlePaymentModal (value: boolean) {
    if (value) {
      this.selectOption(this.paymentModal.option || null)
      if (this.paymentModal.landOnFinalStep) {
        this.ABVersion = 0
      }
      this.selectPlan(this.paymentModal.product || ([StripeProductType.ABBY_START, StripeProductType.ABBY_PRO].includes(this.$planManager.whichPlanCompanyHas() as unknown as StripeProductType) ? this.$planManager.whichPlanCompanyHas() as unknown as StripeProductType : StripeProductType.ABBY_START))
      this.selectFrequency(this.paymentModal.frequency || StripeProductFrequency.YEAR)
    }
  }

  scrollToPayment () {
    window.document.getElementById('payment-modal-card')?.scrollIntoView({ behavior: 'smooth' })
  }

  chooseCEUpsell (value: boolean) {
    this.selectOption(value ? StripeProductType.ABBY_CE : null)
    this.displayCEUpsell = false
    try {
      window.document.querySelector('.payment-modal')?.scrollTo({ top: 0, behavior: 'smooth' })
    } finally {
      this.$ap.sendSegmentTrack({
        event: SegmentEvent.CHOOSE_CE_WITH_PLAN,
        data: {
          value,
        },
      })
      this.$hotjar.sendHotjarEvent(SegmentEvent.CHOOSE_CE_WITH_PLAN as string)
    }
  }

  get canCEUpsellBeShowed () {
    return !!(this.optionItemToDisplay.length && !this.$planManager.has(StripeProductType.ABBY_CE)) && this.ABVersion > 5
  }

  get Frequency () {
    return StripeProductFrequency
  }

  get options () {
    if (this.option && this.optionItemToDisplay.length) {
      return [
        this.option,
      ]
    } else {
      return undefined
    }
  }

  get Plan () {
    return StripeProductType
  }

  selectOption (value: StripeProductType | null) {
    this.option = value
  }

  selectFrequency (value: StripeProductFrequency) {
    // @ts-ignore
    this.frequency = value
  }

  selectPlan (value: StripeProductType) {
    this.plan = value
  }

  closeShowThanks () {
    this.frequency = StripeProductFrequency.YEAR
    this.plan = StripeProductType.ABBY_PRO
    this.option = StripeProductType.ABBY_CE

    this.displayCEUpsell = this.canCEUpsellBeShowed
    this.showThanks = false
    this.$emit('succeed', null)
    clearInterval(this.interval)
    this.close()
  }

  succeed () {
    this.explodeConfetti()
    this.showThanks = true
    this.$campaignsManager.doNotDisplayAnymore(CampaignType.EASTER_EGG_FINISHED)
    this.$hotjar.sendHotjarEvent(SegmentEvent.PAYMENT_SUCCEED as string)
    this.$ap.sendSegmentTrack({
      event: SegmentEvent.PAYMENT_SUCCEED,
      data: {
        frequency: this.frequency,
        plan: this.plan,
        option: this.option,
        displayCEUpsell: this.canCEUpsellBeShowed,
      },
    })
  }

  get abbyStartPurchased () {
    return this.showThanks && this.plan === StripeProductType.ABBY_START
  }

  explodeConfetti () {
    let counter = 1000
    const intervalConfetti = () => {
      const modalElement = document.getElementById('payment-modal__thanks')
      clearInterval(this.interval)
      counter = Math.random() * 2500
      if (modalElement && document.hasFocus()) {
        confetti(modalElement, { elementCount: 30, angle: 65, startVelocity: 55 })
      }
      this.interval = setInterval(intervalConfetti, counter)
    }
    this.interval = setInterval(intervalConfetti, counter)
  }

  get frequencyItemToDisplay () {
    return [
      {
        title: 'Annuelle',
        value: StripeProductFrequency.YEAR,
        choiceAlignment: 'center',
        success: this.$planManager.has(this.plan) && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR,
      },
      {
        title: 'Mensuelle',
        value: StripeProductFrequency.MONTH,
        choiceAlignment: 'center',
        success: this.$planManager.has(this.plan) && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.MONTH,
        disabled: this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR,
      },
    ]
  }

  get planAmount () {
    return {
      [StripeProductType.ABBY_START]: {
        ...PlansConfig[AbbyPlans.ABBY_START].pricing[this.$planManager.abGroupPricing()],
      },
      [StripeProductType.ABBY_PRO]: {
        ...PlansConfig[AbbyPlans.ABBY_PRO].pricing[this.$planManager.abGroupPricing()],
      },
      [StripeProductType.ABBY_CE]: {
        ...AddonsConfig[AbbyAddons.ABBY_CE].pricing[this.$planManager.abGroupPricing()],
      },
    }
  }

  get oneWeekLater () {
    return this.$dayjs().add(1, 'week').format('DD/MM/YYYY')
  }

  get promoCodePro () {
    if (!this.user?.firstname) { return '' }
    const sanitizeString = (str: string) => {
      let _str = str.replace(/[^a-z0-9áéíóúñü .,_-]/gim, '')
      _str = _str.normalize('NFD').replace('-', '')
      _str = _str.normalize('NFD').replace(/[\u0300-\u036F]/g, '')
      _str = _str.normalize('NFD').replace(/\p{Diacritic}/gu, '')
      return _str.trim()
    }
    return `${sanitizeString(this.user.firstname).toUpperCase()}BIGBOSS`
  }

  async copyCode () {
    await this.$clipboard.copy(this.promoCodePro)
    this.$alertsManager.info('Code promo copié avec succès')
  }

  back () {
    if (!this.canCEUpsellBeShowed) {
      this.close()
    }
    this.displayCEUpsell = true
  }

  get taxText () {
    // expérimentation A --> après le 29/11/2023 groupe avec le prix HT
    if (this.$planManager.abGroupPricing() === ABGroupPricing.GROUP_A) {
      return 'mois ht'
    }
    // expérimentation par défault --> avant le 29/11/2023 groupe avec le prix TTC
    return 'mois ttc'
  }

  get isPromotionDay () {
    const isSubProYearly = this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_PRO && !this.$planManager.isTrial() && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR
    const isPromotionDay = this.$dayjs().isBetween('2024-01-02', '2025-03-31', 'day', '[]')

    return !isSubProYearly && isPromotionDay
  }

  get promotionRate () {
    if (this.isPromotionDay) {
      return 0.8
    } else {
      return 1
    }
  }

  get planItemToDisplay () {
    return [
      {
        title: 'Abby Pro',
        ...this.isPromotionDay && this.frequency === StripeProductFrequency.YEAR
          ? {
            subtitle: `<s>${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_PRO][this.frequency].amountByMonth).decimals} €</s> ${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_PRO][this.frequency].amountByMonth * this.promotionRate).decimals} € / ${this.taxText} • ${this.frequency === StripeProductFrequency.YEAR ? 'facturé annuellement' : 'facturé mensuellement'}`,
          }
          : {
            subtitle: `${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_PRO][this.frequency].amountByMonth).decimals} € / ${this.taxText} • ${this.frequency === StripeProductFrequency.YEAR ? 'facturé annuellement' : 'facturé mensuellement'}`,
          },
        imageSrc: '/plans/plan_pro.png',
        value: StripeProductType.ABBY_PRO,
        success: this.$planManager.has(StripeProductType.ABBY_PRO) && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR && !this.$planManager.isTrial(),
      },
      {
        title: 'Abby Start',
        ...this.isPromotionDay && this.frequency === StripeProductFrequency.YEAR
          ? {
            subtitle: `<s>${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_START][this.frequency].amountByMonth).decimals} €</s> ${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_START][this.frequency].amountByMonth * this.promotionRate).decimals} € / ${this.taxText} • ${this.frequency === StripeProductFrequency.YEAR ? 'facturé annuellement' : 'facturé mensuellement'}`,

          }
          : {
            subtitle: `${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_START][this.frequency].amountByMonth).decimals} € / ${this.taxText} • ${this.frequency === StripeProductFrequency.YEAR ? 'facturé annuellement' : 'facturé mensuellement'}`,
          },
        imageSrc: '/plans/plan_start.png',
        value: StripeProductType.ABBY_START,
        success: this.$planManager.has(StripeProductType.ABBY_START) && this.$planManager.whichFrequencyPlanCompanyHas() === StripeProductFrequency.YEAR && !this.$planManager.isTrial(),
        disabled: (this.$planManager.has(StripeProductType.ABBY_PRO) && !this.$planManager.isTrial()) || (this.$planManager.has([StripeProductType.ABBY_CREATION_START, StripeProductType.ABBY_CREATION_START_PREMIUM])),

      },
    ]
  }

  get optionItemToDisplay () {
    if (!this.$planManager.hasRelatedCESubscription() && this.$planManager.has(StripeProductType.ABBY_CE)) { return [] }
    return [
      {
        title: 'Le CE des indépendants',
        // subtitle: 'cc',
        subtitle: `${this.$amount.formatCents(this.planAmount[StripeProductType.ABBY_CE][this.frequency].amountByMonth).decimals} € / ${this.taxText} • ${this.frequency === StripeProductFrequency.YEAR ? 'facturé annuellement' : 'facturé mensuellement'}`,
        imageSrc: '/ce-landing/icon-1.png',
        value: StripeProductType.ABBY_CE,
        success: this.$planManager.has(StripeProductType.ABBY_CE),
      },
      ...!this.$planManager.has(StripeProductType.ABBY_CE)
        ? [{
          title: 'Sans option',
          choiceAlignment: 'center',
          value: null,
          titleNormal: true,
        }]
        : [],
    ]
  }
}
