import { PersonalServiceActivity } from '../enums/personalServiceActivityType.enum';

export const PersonalServiceActivities = [
  {
    value: PersonalServiceActivity.GARDE_ENFANT_HANDICAPE,
    label: 'Garde d\'enfant handicapé',
  },
  {
    value: PersonalServiceActivity.ACCOMPAGNEMENT_ENFANT_HANDICAPE,
    label: 'Accompagnement d\'enfant handicapé',
  },
  {
    value: PersonalServiceActivity.AIDE_HUMAINE,
    label: 'Aide humaine',
  },
  {
    value: PersonalServiceActivity.CONDUITE_VEHICULE_PERSONNEL,
    label: 'Conduite du véhicule personnel (assistance)',
  },
  {
    value: PersonalServiceActivity.AIDE_AU_DEPLACEMENT,
    label: 'Aide au déplacement',
  },
  {
    value: PersonalServiceActivity.ENTRETIEN_DE_LA_MAISON,
    label: 'Entretien de la maison',
  },
  {
    value: PersonalServiceActivity.JARDINAGE,
    label: 'Jardinage',
  },
  {
    value: PersonalServiceActivity.BRICOLAGE,
    label: 'Bricolage',
  },
  {
    value: PersonalServiceActivity.GARDE_ENFANT_PLUS_DE_6_ANS,
    label: 'Garde d\'enfant + 6 ans',
  },
  {
    value: PersonalServiceActivity.SOUTIEN_SCOLAIRE_COURS_A_DOMICILE,
    label: 'Soutien scolaire à domicile / Cours à domicile (coach sportif ...)',
  },
  {
    value: PersonalServiceActivity.SOIN_ESTHETIQUE_A_DOMICILE,
    label: 'Soins d’esthétique à domicile',
  },
  {
    value: PersonalServiceActivity.PREPARATION_REPAS_A_DOMICILE,
    label: 'Préparation de repas à domicile',
  },
  {
    value: PersonalServiceActivity.LIVRAISON_REPAS_A_DOMICILE,
    label: 'Livraison de repas à domicile',
  },
  {
    value: PersonalServiceActivity.COLLECTE_ET_LIVRAISON_DE_LINGE_REPASSE,
    label: 'Collecte et livraison de linge repassé',
  },
  {
    value: PersonalServiceActivity.LIVRAISON_COURSES_A_DOMICILE,
    label: 'Livraison de courses à domicile',
  },
  {
    value: PersonalServiceActivity.ASSISTANCE_INFORMATIQUE,
    label: 'Assistance informatique',
  },
  {
    value: PersonalServiceActivity.SOINS_ET_PROMENADES_ANIMAUX_DE_COMPAGNIE_POUR_PERSONNES_DEPENDANTES,
    label: 'Soins et promenades d’animaux de compagnie pour les personnes dépendantes',
  },
  {
    value: PersonalServiceActivity.MAINTENANCE_ENTRETIEN_ET_VIGILANCE_DE_LA_RESIDENCE_PRINCIPALE,
    label: 'Maintenance, entretien et vigilance de la résidence principale et secondaire',
  },
  {
    value: PersonalServiceActivity.ASSISTANCE_ADMINISTRATIVE,
    label: 'Assistance administrative',
  },
  {
    value: PersonalServiceActivity.ACCOMPAGNEMENT_ENFANT_PLUS_DE_6_ANS,
    label: 'Accompagnement d\'enfant + 6 ans',
  },
  {
    value: PersonalServiceActivity.TELEASSISTANCE,
    label: 'Téléassistance',
  },
  {
    value: PersonalServiceActivity.INTERPRETE_LANGUE_DES_SIGNES,
    label: 'Interprète en langue des signes, technicien de l’écrit et codeur en langage parlé complété',
  },
  {
    value: PersonalServiceActivity.CONDUITE_VEHICULE_PERSONNEL_EN_CAS_INVALIDITE_TEMPORAIRE,
    label: 'Conduite du véhicule personnel en cas d’invalidité temporaire',
  },
  {
    value: PersonalServiceActivity.ACCOMPAGNEMENT_DEPLACEMENTS_EN_DEHORS_DU_DOMICILE,
    label: 'Accompagnement dans les déplacements en dehors du domicile',
  },
  {
    value: PersonalServiceActivity.ASSISTANCE_PERSONNES_TEMPORAIREMENT_DEPENDANTES_A_DOMICILE,
    label: 'Assistance aux personnes temporairement dépendantes à domicile',
  },
  {
    value: PersonalServiceActivity.COORDINATION_ET_GESTION,
    label: 'Coordination et gestion',
  },
  {
    value: PersonalServiceActivity.DIVERS_NON_ELIGIBLE,
    label: 'Divers – Non éligible',
  },
];