
import { Component, ModelSync, Prop, Vue } from 'nuxt-property-decorator'
import { ValidationProvider } from 'vee-validate'
import AsListSelection from '~/components-legacy/inputs/ASListSelection.vue'

@Component({
  name: 'as-choice-input',
  components: {
    AsListSelection,
    ValidationProvider,
  },
})
export default class ASChoiceInput extends Vue {
  @Prop({ default: '' })
  rules!: object | string;

  @Prop({ default: false })
  hideLabel!: boolean | string;

  @Prop({ required: false })
  tooltip!: string;

  @Prop({ default: 'primary' })
  tooltipIconColor!: string;

  @Prop({ default: 'help_outline' })
  tooltipIcon!: string;

  @Prop({ default: false })
  items!: Array<{ text: string, value: any }>;

  get _hideLabel () {
    return !!this.hideLabel || typeof this.hideLabel === 'string'
  }

  get _dense () {
    return !!this.$attrs.dense || typeof this.$attrs.dense === 'string'
  }

  get hasSlot () {
    return !!this.$slots.tooltip || !!this.$scopedSlots.tooltip
  }

  @ModelSync('value', 'input')
  innerValue: any;
}
