import { PersonalServiceActivity } from '@abby/shared'
import { ProductForm } from '~/logic/contexts/product/domain/form/product.form'
import { useProductRepository } from '~/logic/contexts/product/infrastructure/repository/product.repository'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export const useUpdateProductPSA = () => {
  const productRepository = useProductRepository()
  const alertManager = useAlertManager()

  return async (product: ProductForm) => {
    if (product.id) {
      const editedProduct = await productRepository.updateProductPersonalServiceActivity({
        ...product,
        id: product.id,
        personalServiceActivity: product.personalServiceActivity || PersonalServiceActivity.AIDE_HUMAINE, // uniquement à but de typage
      })
      alertManager.success('Le produit a bien été modifié')
      return editedProduct
    }
  }
}
