import { ProductType as NewProductType } from '..';

enum ProductType {
  SALE_OF_GOODS = 1,
  SERVICE_DELIVERY = 2,
  COMMERCIAL_OR_CRAFT_SERVICES = 3,
  SALE_OF_MANUFACTURED_GOODS = 4,
  DISBURSEMENT = 5,
}

export const mapLegacyProductTypeToProductType = {
  [ProductType.COMMERCIAL_OR_CRAFT_SERVICES]: NewProductType.COMMERCIAL_OR_CRAFT_SERVICES,
  [ProductType.SALE_OF_GOODS]: NewProductType.SALE_OF_GOODS,
  [ProductType.SERVICE_DELIVERY]: NewProductType.SERVICE_DELIVERY,
  [ProductType.SALE_OF_MANUFACTURED_GOODS]: NewProductType.SALE_OF_MANUFACTURED_GOODS,
  [ProductType.DISBURSEMENT]: NewProductType.DISBURSEMENT,
};

export const mapProductTypeToLegacyProductType = {
  [NewProductType.COMMERCIAL_OR_CRAFT_SERVICES]: ProductType.COMMERCIAL_OR_CRAFT_SERVICES,
  [NewProductType.SALE_OF_GOODS]: ProductType.SALE_OF_GOODS,
  [NewProductType.SERVICE_DELIVERY]: ProductType.SERVICE_DELIVERY,
  [NewProductType.SALE_OF_MANUFACTURED_GOODS]: ProductType.SALE_OF_MANUFACTURED_GOODS,
  [NewProductType.DISBURSEMENT]: ProductType.DISBURSEMENT,
};
