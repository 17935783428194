import type { CreateNPS, INPS } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class NPS extends Resource {
  create (params: CreateNPS): Promise<INPS> {
    return this.axios.$post('/nps', params)
  }

  getLatest (): Promise<INPS> {
    return this.axios.$get('/nps/latest')
  }
}
