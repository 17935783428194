
import Vue from 'vue'
import { Component, Getter } from 'nuxt-property-decorator'
import { AbbyPlans, CampaignType, ICreationCompany, IUser, SegmentEvent, StripeProductType } from '@abby/core-legacy'
import { WebinarType } from '@abby/shared'
import TimeTrackingTimer from '~/components-legacy/timeTracking/TimeTrackingTimer.vue'
import UserNavigationMenu from '~/components-legacy/layout/UserNavigationMenu.vue'
import { CampaignTrigger } from '~/plugins/campaignsManager'
import { CommunityEvent } from '~/store/app'
import GuideMenu from '~/components-legacy/menus/GuideMenu.vue'
import AbbyProActionNew from '~/components-legacy/cards/AbbyProActionNew.vue'
import { IAuthUser } from '~/store/auth'
import CalendarEvent from '~/components-legacy/cards/CalendarEvent.vue'
import { useLayout } from '~/composables/useLayout'

@Component({
  components: {
    CalendarEvent,
    AbbyProActionNew,
    GuideMenu,
    TimeTrackingTimer,
    FirstStepMenu: () => import('~/components-legacy/menus/FirstStepMenu.vue'),
    UserNavigationMenu,
  },
  setup () {
    const { offsetTop } = useLayout()
    return {
      offsetTop,
    }
  },
})
export default class Navigation extends Vue {
  @Getter('app/appLoading') appLoading!: boolean;

  @Getter('auth/connectedFromDashboard')
  connectedFromDashboard!: any | null;

  @Getter('webinar/webinar')
  webinar!: WebinarType

  @Getter('webinar/queryLoading')
  webinarLoading!: boolean

  @Getter('auth/user')
  currentUser!: IUser

  @Getter('company/isInCreation')
  isInCreation!: boolean

  @Getter('company/isCompanyCreated')
  isCompanyCreated!: boolean

  @Getter('company/creation')
  creation!: ICreationCompany;

  @Getter('company/isVerified')
  isVerified!: boolean

  @Getter('timeTracking/timerIsRunning')
  timerIsRunning!: boolean

  @Getter('onboarding/allStepsAreCompleted') allStepsAreCompleted!: boolean;
  @Getter('auth/user') user!: IAuthUser;

  @Getter('guide/countNotCompleted') countNotCompleted!: number

  @Getter('app/communityEvents') _communityEvents!: CommunityEvent[]
  @Getter('app/communityEventInNotification') communityEventInNotification!: CommunityEvent

  offsetTop!: number

  get displayGuideMenu () {
    return this.countNotCompleted && this.$campaignsManager.isDisplayable(CampaignType.START_3_GESTION)
  }

  get displayLearnOnHomepageButton () {
    return !this.$campaignsManager.isDisplayable(CampaignType.START_3_GESTION) && this.$route.path !== '/'
  }

  onWebinarRedirect () {
    this.$ap.sendSegmentTrack({
      event: SegmentEvent.CALENDLY_REDIRECTION,
      data: {
        source: 'events-menu',
        webinar: this.webinar,
      },
    })
  }

  get hasDouble2Offer () {
    return !this.$campaignsManager.isDisplayable(CampaignType.DOUBLE_2) && (this.$planManager.whichPlanCompanyHas() === AbbyPlans.ABBY_FREE || this.$planManager.isTrial())
  }

  get communityEvents () {
    const query = {
      email: this.user?.email,
      name: this.user?.fullName,
      phone_number: this.user?.phone,
    }

    function serialize (obj: any) {
      const str = '?' + Object.keys(obj).reduce(function (a, k) {
        if (k && obj[k]) {
          // @ts-ignore
          a.push(k + '=' + encodeURIComponent(obj[k]))
        }
        return a
      }, []).join('&')
      return str
    }

    const _query = serialize(query)

    return [
      ...this._communityEvents.filter(e => e.menu),
    ]
  }

  get miniAbbyLogo () {
    return this.$brandManager.miniLogoUrl()
  }

  get fullName () {
    return `${this.currentUser?.firstname} ${this.currentUser?.lastname}`.trim()
  }

  get displayStepButton () {
    if (this.isInCreation) { return !this.allStepsAreCompleted || (!this.isVerified && this.isCompanyCreated && this.isInCreation) }
  }

  get isPaidAndMonthly () {
    return [AbbyPlans.ABBY_PRO, AbbyPlans.ABBY_START].includes(this.$planManager.whichPlanCompanyHas()) && this.$planManager.whichFrequencyPlanCompanyHas() === 'month'
  }

  get displayUpgradePlans () {
    return !this.$planManager.has(StripeProductType.ABBY_PRO) || this.$planManager.isTrial() || this.isPaidAndMonthly
  }

  displayIdeaBadge = false
  displayCommunityEventsBadge = false

  get ideaBadgeCanBeDisplayed () {
    return this.$campaignsManager.isDisplayable(CampaignType.IDEAS_MENU, {
      trigger: CampaignTrigger.INSTANT,
      maximumDisplayFrequency: 70,
    })
  }

  get communityCanBeDisplayed () {
    return this.$campaignsManager.isDisplayable(CampaignType.COMMUNITY_EVENTS_MENU, {
      trigger: CampaignTrigger.INSTANT,
      maximumDisplayFrequency: 70,
    })
  }

  openFrill () {
    this.$store.dispatch('app/openFrill')
    this.$campaignsManager.register({
      type: CampaignType.IDEAS_MENU,
      trigger: CampaignTrigger.INSTANT,
      maximumDisplayFrequency: 70, // 2 semaines
      // maximumDisplayFrequency: 20_160, // 2 semaines
      priority: 3,
      action: () => {},
    })
    this.displayIdeaBadge = false
  }

  communityEventsMenuOpened (value: true) {
    if (value && this.displayCommunityEventsBadge) {
      this.$campaignsManager.register({
        type: CampaignType.COMMUNITY_EVENTS_MENU,
        trigger: CampaignTrigger.INSTANT,
        maximumDisplayFrequency: 70, // 2 semaines
        // maximumDisplayFrequency: 20_160, // 2 semaines
        priority: 3,
        action: () => {},
      })
    }
    this.displayCommunityEventsBadge = false
  }

  logoutAccountFromDashboard () {
    const id = this.currentUser.id
    this.logout()
    let baseURL
    if (this.$config.nodeEnv === 'development') {
      baseURL = 'http://dashboard.localhost:3000'
    } else if (this.$config.nodeEnv === 'staging') {
      baseURL = 'https://dashboard-next.abby.fr'
    } else {
      baseURL = 'https://dashboard.abby.fr'
    }
    window.open(`${baseURL}/clients/${id}`, '_self')
  }

  openMobileMenu () {
    this.$store.dispatch('app/openMobileMenu')
  }

  get adminFullName () {
    return this.connectedFromDashboard?.fullName
  }

  async logout () {
    try {
      await this.$store.dispatch('auth/logout')
    } catch (e) {
      this.$alertsManager.autoError(e)
    }
  }

  mounted () {
    setTimeout(() => {
      this.$campaignsManager.register({
        type: CampaignType.EVENT_INVITATION,
        trigger: CampaignTrigger.INSTANT,
        maximumDisplayFrequency: 720,
        priority: 3,
        action: () => {
          const eventToNotify = this.communityEvents.find((e) => {
            return !e.banner && this.$dayjs(e.date).isSameOrAfter(this.$dayjs().subtract(2, 'd')) && this.$dayjs(e.date).isSameOrBefore(this.$dayjs().add(2, 'd'))
          })
          if (eventToNotify) {
            this.$store.dispatch('app/openCommunityEventInNotification', eventToNotify)
          }
        },
      })
    }, 45_000)
    this.displayCommunityEventsBadge = this.communityCanBeDisplayed
    this.displayIdeaBadge = this.ideaBadgeCanBeDisplayed
  }

  closeEventNotification () {
    this.$store.dispatch('app/closeCommunityEventInNotification')
  }

  openCommunityEvent (event: CommunityEvent) {
    this.closeEventNotification()
    this.$store.dispatch('app/openCommunityEventInModal', event)
  }
  //   Commentaire à supprimer
}

