import { PaymentMethod, BillingState, BillingType } from '../../../Enum/BillingConfiguration';
import { IBillingColumn, IBillingStyle } from '../../billing/IBillingConfiguration';
import { LegalStatus } from '../../../Enum/LegalStatus';
import { IAdditionalLogos } from '../billing/IAdditionalLogos';
import { IAttachment } from '../billing/IAttachment';
import { Currency } from '../../../Enum/Currency';

export enum EJSTemplate {
  INCOME_STATEMENT = 'templates/incomeStatement.ejs',
}

export enum EJSPartial {
  BILLING_HEADER = 'partials/billing/billing__header.ejs',
  BILLING_FOOTER = 'partials/billing/billing__footer.ejs',
  GENERIC_FOOTER = 'partials/generic/generic__footer.ejs',
  EMPTY_HEADER = 'partials/generic/empty__header.ejs',
}

export enum BillingTemplate {
  TEMPLATE_1 = 1,
  TEMPLATE_2 = 2,
  TEMPLATE_3 = 3,
}

export type BillingDocumentPDFData = {
  // Utils
  BillingType: typeof BillingType,
  BillingState: typeof BillingState,
  PaymentMethod: typeof PaymentMethod,
  BillingTemplate: typeof BillingTemplate,
  LegalStatus: typeof LegalStatus,
  // Type
  billingType: BillingType | null,
  billingState: BillingState | null,
  // Number
  number?: string | null,
  // Logo
  logo?: string | null,
  shape?: string | null,
  style?: IBillingStyle | null,
  template: BillingTemplate
  social: {
    facebook?: string
    instagram?: string
    linkedin?: string
    website?: string
  } | null;
  // Emitter
  creationIsFinished: boolean,
  emitter: {
    name: string | null,
    fullName: string | null,
    email: string | null,
    phone: string | null,
    commercialName: string | null,
    siret: string | null,
    vatNumber: string | null,
    address: string | null,
    additionalAddress?: string | null,
    zipCode: string | null,
    city: string | null,
    country: string | null,
    hasVat: boolean,
    legalStatus: LegalStatus | null,
    sapAgreement: string | null,
  },
  // Recipient
  recipient: {
    hasCompany: boolean,
    name: string | null,
    commercialName: string | null,
    recipientCompanyContactName: string | null,
    siret?: string | null,
    vatNumber?: string | null,
    address: string | null,
    additionalAddress?: string | null,
    zipCode: string | null,
    city: string | null,
    deliveryLocationIsSame: boolean,
    displayDeliveryAddress: boolean,
    deliveryAddress: string | null,
    deliveryAdditionalAddress?: string | null,
    deliveryZipCode: string | null,
    deliveryCity: string | null,
    country: string | null,
    deliveryCountry: string | null,
  },
  // Payment
  hasTransferPaymentMethod: boolean,
  paymentDelay?: string | null,
  latePenalty?: string | null,
  lumpSumCompensation?: string | null,
  discountAdvancePayment?: string | null,
  paymentMethod?: string | null,
  bank?: string | null,
  iban?: string | null,
  bic?: string | null,
  paymentLink?: string | null,
  // Signature
  useSignature?: boolean | null,
  signatureLink?: string | null,
  // Notes
  headerNote?: string | null,
  footerNote?: string | null,
  // CGV
  generalTermsAndConditionsOfSale: string | null
  mentionMediator: string | null
  // Dates
  date: string
  dueDate?: string | null
  deliveryDate?: string | null
  validityDate?: string | null
  paidDate?: string | null
  // Products
  products: Array<{
    reference: string | null
    designation: string | null
    description?: string | null
    quantity?: number | null
    productUnit?: string | undefined | null
    unitPrice: string | null
    vatPercentage?: string | null
    priceWithoutTaxBeforeDiscount: string | null
    discount?: string | null,
    index?: number,
    productTypeRequiredMention?: string | null,
  }>
  // Totals
  priceWithoutTaxBeforeDiscount: string;
  discount: string;
  priceWithoutTax: string;
  vatAmount: string;
  priceTotalTax: string;
  vatDetails: Array<{ name: string, amount: string }>;
  remainingVatDetails: Array<{ name: string, amount: string }>;
  remainingVatAmount?: string;
  hasAssociatedAdvances: boolean;
  hasDiscount: boolean;
  associatedAdvances: Array<{ number: string, priceTotalTax: string }> | null;
  totalAssociatedAsset: string | null;
  remainingPriceToPayTotalTax: string | null;
  remainingPriceToPayWithoutTax: string | null;
  // VAT Mention
  vatMention?: string | null;
  // Information
  displayMemberOfAnApprovedAssociation: boolean
  displayGoodForApproval: boolean
  displayAbbyLogo: boolean
  displayFullName: boolean
  displayEmail: boolean
  displayPhoneNumber: boolean
  displayLegalStatus?: boolean
  displayRequiredMentionsProduct?: boolean
  displayTextEmail?: string | null
  displayTextPhoneNumber?: string | null
  columns: Array<IBillingColumn>
  preRenderNumber?: string | null,
  locale?: string,
  currency: Currency,
  isDefaultCurrency: boolean,
  currencyRate: number,
  currencyDate: Date,
  logosFooter: { image: string, type: string }[] | IAdditionalLogos[],
};

export type GeneratePdfOptions = {
  template?: EJSTemplate,
  data: any,
  PDFOpts?: Partial<any>,
  options?: {
    headerTemplate?: EJSPartial,
    headerData?: any,
    footerTemplate?: EJSPartial,
    footerData?: any,
    specimen?: boolean
    shapeUrl?: string
  }
};

export type GenerateBillingOptions = {
  companyId: string,
  data: BillingDocumentPDFData,
  attachments: IAttachment[],
} & GeneratePdfOptions;
