import type {
  CreateIssue,
  GitlabLabel, QueryIssues, ReadIssue, UpdateIssue,
} from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Issue extends Resource {
  getIssues (query: QueryIssues): Promise<{ totalItems: number, data: ReadIssue[] }> {
    return this.axios.$get('/issue', { params: query })
  }

  getLabelsList (): Promise<GitlabLabel[]> {
    return this.axios.$get('/issue/labels')
  }

  createIssue (payload: CreateIssue): Promise<ReadIssue> {
    return this.axios.$post('/issue', payload)
  }

  updateIssue (payload: UpdateIssue): Promise<ReadIssue> {
    const { id, ...data } = payload
    return this.axios.$put(`/issue/${id}`, data)
  }

  deleteIssue (id: string): Promise<boolean> {
    return this.axios.$delete(`/issue/${id}`)
  }
}
