import { useQuery, useQueryClient, useMutation } from '@tanstack/vue-query'
import { useContext } from '@nuxtjs/composition-api'
import axios from 'axios'
import {
  CreateContact,
  RegisterCustomer,
  UpdateContactNotes,
  UpdateContact,
  SetDefaultContact,
} from '@abby/shared'

import { Ref } from 'vue'
import { ContactItemHttpMapper } from '~/logic/contexts/client/infrastructure/mapper/contactItemHttp.mapper'
import { ClientStatisticsHttpMapper } from '~/logic/contexts/client/infrastructure/mapper/clientStatisticsHttp.mapper'
import { ContactHttpMapper } from '~/logic/contexts/client/infrastructure/mapper/contactHttp.mapper'
import { ContactForm } from '~/logic/contexts/client/domain/form/contact.form'
import { useAlertManager } from '~/composables/shared/manager/useAlertManager'

export type ContactFilterQuery = {
  search: Ref<string | undefined>,
  archived: Ref<boolean>,
}

export type ContactPaginateQuery = {
  page: Ref<number>,
  limit: Ref<number>,
} & ContactFilterQuery

export const useContactRepository = () => {
  const queryClient = useQueryClient()
  const alertManager = useAlertManager()
  const { $backend } = useContext()

  const paginate = ({
    page,
    limit,
    search,
    archived,
  }: ContactPaginateQuery) => {
    return useQuery({
      refetchOnWindowFocus: false,
      queryKey: ['contact', { page, limit, search, archived }],
      queryFn: async ({ signal }) => {
        const CancelToken = axios.CancelToken
        const source = CancelToken.source()
        signal?.addEventListener('abort', () => source.cancel())
        const data = await $backend.contact.paginate({
          page: page.value,
          search: search.value?.length ? search.value : undefined,
          limit: limit.value,
          archived: archived.value,
          cancelToken: source.token,
        })
        return {
          ...data,
          docs: data.docs.map(ContactItemHttpMapper.toDomain),
        }
      },
      onError: (error) => {
        alertManager.autoError(error)
      },
      keepPreviousData: true,
    })
  }

  const refreshPaginate = async () => {
    await queryClient.invalidateQueries(['contact'])
  }

  const { mutateAsync: archiveContact } = useMutation({
    mutationFn: (id: string) => $backend.contact.archive(id),
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: unarchiveContact } = useMutation({
    mutationFn: (id: string) => $backend.contact.unarchive(id),
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: removeContact } = useMutation({
    mutationFn: (id: string) => $backend.contact.remove(id),
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const getContactById = (id: string) => {
    return $backend.contact.fetchContact(id)
  }

  const getContactStatisticsById = async (id: string) => {
    const result = await $backend.contact.fetchContactStatistics(id)
    return ClientStatisticsHttpMapper.toDomain(result)
  }

  const getContactSapData = async (id: string) => {
    return await $backend.contact.fetchContactSapData(id)
  }

  const updateContactNotes = async (id: string, notes: UpdateContactNotes) => {
    return await $backend.contact.updateContactNotes(id, notes)
  }

  const updateDefineAsPrimaryContact = async (
    organizationId: string,
    payload: SetDefaultContact,
  ) => {
    const editedContact = await $backend.contact.updateDefineAsPrimaryContact(
      organizationId,
      payload,
    )
    await refreshPaginate()
    return ContactHttpMapper.toDomain(editedContact)
  }

  const { mutateAsync: createContact } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationFn: async (payload: ContactForm) => {
      const newContact: CreateContact = {
        firstname: payload.firstname,
        lastname: payload.lastname,
        emails: payload.emails,
        jobTitle: payload.jobTitle,
        phone: payload.phone,
        billingAddress: payload.billingAddress,
        deliveryAddress: payload.deliveryAddress,
        bankInformations: payload.bankInformations,
        preferences: payload.preferences,
      }
      const result = await $backend.contact.create(newContact)
      return ContactHttpMapper.toDomain(result)
    },
    onError: (error) => {
      alertManager.autoError(error)
    },
    onSuccess: async () => {
      await refreshPaginate()
    },
  })
  const { mutateAsync: updateContact } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationKey: ['contact'],
    mutationFn: async (contact: ContactForm) => {
      if (!contact?.id) {
        return
      }
      const updateContact: UpdateContact = {
        firstname: contact.firstname,
        lastname: contact.lastname,
        emails: contact.emails,
        jobTitle: contact.jobTitle,
        phone: contact.phone,
        billingAddress: contact.billingAddress,
        deliveryAddress: contact.deliveryAddress,
        bankInformations: contact.bankInformations,
        preferences: contact.preferences,
      }
      const result = await $backend.contact.update(contact.id, updateContact)
      return ContactHttpMapper.toDomain(result)
    },
    onError: (error) => {
      alertManager.autoError(error)
    },
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  const { mutateAsync: registerTierPrestation } = useMutation({
    // TODO use optimistic behaviour when tanstack is upgraded to 5.x (currently there is a bug with overloads in 4.x https://github.com/TanStack/query/issues/5008)
    mutationKey: ['contactTP'],
    mutationFn: async (newContact: RegisterCustomer) => {
      await $backend.tierPrestation.registerCustomer(newContact)
    },
    onError: (error) => {
      alertManager.autoError(error)
    },
    onSuccess: async () => {
      await refreshPaginate()
    },
  })

  return {
    paginate,
    getContactById,
    getContactSapData,
    getContactStatisticsById,
    updateContactNotes,
    updateDefineAsPrimaryContact,
    archiveContact,
    unarchiveContact,
    removeContact,
    createContact,
    updateContact,
    registerTierPrestation,
  }
}
