import { ReadBillingDetails, Money } from '@abby/shared'
import { BillingDetails } from '~/logic/contexts/billing/domain/entity/billingDetails.entity'

export class BillingDetailsHttpMapper {
  static toDomain (doc: ReadBillingDetails) {
    return BillingDetails.create(
      {
        id: doc.id,
        remainingReconciliateAmount: Money.create({ amountInCents: doc.remainingReconciliateAmount }),
        lines: doc.lines.map(line => ({
          ...line,
          unitPrice: Money.create({ amountInCents: line.unitPrice }),
          priceWithoutTaxBeforeDiscount: Money.create({ amountInCents: line.priceWithoutTaxBeforeDiscount }),
          priceWithoutTaxAfterDiscount: Money.create({ amountInCents: line.priceWithoutTaxAfterDiscount }),
          discountAmount: Money.create({ amountInCents: line.discountAmount || 0 }),
          priceTotalTax: Money.create({ amountInCents: line.priceTotalTax }),
          // convertedAmounts: line.convertedAmounts
          //   ? {
          //     priceWithoutTaxBeforeDiscount: Money.create({
          //       amountInCents: line.convertedAmounts.priceWithoutTaxBeforeDiscount,
          //       currency: doc.currency.currencyCode,
          //     }),
          //     discountAmount: Money.create({
          //       amountInCents: line.convertedAmounts?.discountAmount || 0,
          //       currency: doc.currency.currencyCode,
          //     }),
          //     priceWithoutTaxAfterDiscount: Money.create({
          //       amountInCents: line.convertedAmounts.priceWithoutTaxAfterDiscount,
          //       currency: doc.currency.currencyCode,
          //     }),
          //     priceTotalTax: Money.create({
          //       amountInCents: line.convertedAmounts.priceTotalTax,
          //       currency: doc.currency.currencyCode,
          //     }),
          //     vatAmount: line.convertedAmounts.vatAmount,
          //   }
          //   : undefined,
        })),
        currency: {
          ...doc.currency,
          currencyDate: new Date(doc.currency.currencyDate),
        },
        total: {
          ...doc.total,
          totalAmountWithoutTaxBeforeDiscount: Money.create({ amountInCents: doc.total.totalAmountWithoutTaxBeforeDiscount }),
          totalAmountWithoutTaxAfterDiscount: Money.create({ amountInCents: doc.total.totalAmountWithoutTaxAfterDiscount }),
          remainingAmountWithoutTax: Money.create({ amountInCents: doc.total.remainingAmountWithoutTax }),
          remainingAmountWithTax: Money.create({ amountInCents: doc.total.remainingAmountWithTax }),
          totalAmountWithTaxAfterDiscount: Money.create({ amountInCents: doc.total.totalAmountWithTaxAfterDiscount }),
          vatDetails: doc.total.vatDetails.map(vat => ({
            ...vat,
            amount: Money.create({ amountInCents: vat.amount }),
          })),
          advances: doc.total.advances?.map(advance => ({
            ...advance,
            totalAmountWithTax: Money.create({ amountInCents: advance.totalAmountWithTax }),
          })),
          discountAmount: Money.create({ amountInCents: doc.total.discountAmount || 0 }),
          // convertedAmounts: doc.total.convertedAmounts
          //   ? {
          //     totalAmountWithoutTaxBeforeDiscount: Money.create({
          //       amountInCents: doc.total.convertedAmounts.totalAmountWithoutTaxBeforeDiscount,
          //       currency: doc.currency.currencyCode,
          //     }),
          //     totalAmountWithoutTaxAfterDiscount: Money.create({
          //       amountInCents: doc.total.convertedAmounts.totalAmountWithoutTaxAfterDiscount,
          //       currency: doc.currency.currencyCode,
          //     }),
          //     remainingAmountWithoutTax: Money.create({
          //       amountInCents: doc.total.convertedAmounts.remainingAmountWithoutTax,
          //       currency: doc.currency.currencyCode,
          //     }),
          //     remainingAmountWithTax: Money.create({
          //       amountInCents: doc.total.convertedAmounts.remainingAmountWithTax,
          //       currency: doc.currency.currencyCode,
          //     }),
          //     vatDetails: doc.total.convertedAmounts.vatDetails.map(vat => ({
          //       ...vat,
          //       amount: Money.create({ amountInCents: vat.amount, currency: doc.currency.currencyCode }),
          //     })),
          //     advances: doc.total.convertedAmounts.advances?.map(advance => ({
          //       ...advance,
          //       totalAmountWithTax: Money.create({ amountInCents: advance.totalAmountWithTax, currency: doc.currency.currencyCode }),
          //     })),
          //     discountAmount: Money.create({ amountInCents: doc.total.convertedAmounts.discountAmount || 0, currency: doc.currency.currencyCode }),
          //   }
          //   : undefined,
        },
      },
    )
  }
}
