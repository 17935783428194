import { ProductUnit as NewProductUnit } from '..';

enum ProductUnit {
  UNITE = 14,
  GRAMME = 1,
  HEURE = 2,
  JOUR = 3,
  FORFAIT = 22,
  ANNEE = 23,
  CARACTERE = 24,
  LIGNE = 25,
  LICENCE = 26,
  ARTICLE = 21,
  MOIS = 20,
  KILOGRAMME = 4,
  KILOMETRE = 5,
  LITRE = 6,
  LOT = 7,
  METRE = 8,
  METRE_CARRE = 9,
  METRE_CUBE = 10,
  METRE_LINEAIRE = 11,
  PERSONNE = 12,
  TONNE = 13,
  MOT = 15,
  PAGE = 16,
  FEUILLET = 17,
  PARAGRAPHE = 18,
  MINUTE = 19,
}

enum UnitTypeCode {
  HEURE = 'HEURE',
  FORFAIT = 'FORFAIT',
}

export const mapLegacyUnitToUnit = {
  [ProductUnit.UNITE]: NewProductUnit.UNIT,
  [ProductUnit.GRAMME]: NewProductUnit.GRAM,
  [ProductUnit.HEURE]: NewProductUnit.HOUR,
  [ProductUnit.JOUR]: NewProductUnit.DAY,
  [ProductUnit.FORFAIT]: NewProductUnit.FIXED_RATE,
  [ProductUnit.ANNEE]: NewProductUnit.YEAR,
  [ProductUnit.CARACTERE]: NewProductUnit.CHARACTER,
  [ProductUnit.LIGNE]: NewProductUnit.LINE,
  [ProductUnit.LICENCE]: NewProductUnit.LICENSE,
  [ProductUnit.ARTICLE]: NewProductUnit.ARTICLE,
  [ProductUnit.MOIS]: NewProductUnit.MONTH,
  [ProductUnit.KILOGRAMME]: NewProductUnit.KILOGRAM,
  [ProductUnit.KILOMETRE]: NewProductUnit.KILOMETER,
  [ProductUnit.LITRE]: NewProductUnit.LITER,
  [ProductUnit.LOT]: NewProductUnit.BATCH,
  [ProductUnit.METRE]: NewProductUnit.METER,
  [ProductUnit.METRE_CARRE]: NewProductUnit.SQUARE_METER,
  [ProductUnit.METRE_CUBE]: NewProductUnit.CUBIC_METER,
  [ProductUnit.METRE_LINEAIRE]: NewProductUnit.LINEAR_METER,
  [ProductUnit.PERSONNE]: NewProductUnit.PERSON,
  [ProductUnit.TONNE]: NewProductUnit.TON,
  [ProductUnit.MOT]: NewProductUnit.WORD,
  [ProductUnit.PAGE]: NewProductUnit.PAGE,
  [ProductUnit.FEUILLET]: NewProductUnit.LEAFLET,
  [ProductUnit.PARAGRAPHE]: NewProductUnit.PARAGRAPH,
  [ProductUnit.MINUTE]: NewProductUnit.MINUTE,
};

export const mapLegacyUnitTpToUnit = {
  [NewProductUnit.HOUR]: UnitTypeCode.HEURE,
  [NewProductUnit.FIXED_RATE]: UnitTypeCode.FORFAIT,
};

export const mapUnitToLegacyUnit = {
  [NewProductUnit.UNIT]: ProductUnit.UNITE,
  [NewProductUnit.GRAM]: ProductUnit.GRAMME,
  [NewProductUnit.HOUR]: ProductUnit.HEURE,
  [NewProductUnit.DAY]: ProductUnit.JOUR,
  [NewProductUnit.FIXED_RATE]: ProductUnit.FORFAIT,
  [NewProductUnit.YEAR]: ProductUnit.ANNEE,
  [NewProductUnit.CHARACTER]: ProductUnit.CARACTERE,
  [NewProductUnit.LINE]: ProductUnit.LIGNE,
  [NewProductUnit.LICENSE]: ProductUnit.LICENCE,
  [NewProductUnit.ARTICLE]: ProductUnit.ARTICLE,
  [NewProductUnit.MONTH]: ProductUnit.MOIS,
  [NewProductUnit.KILOGRAM]: ProductUnit.KILOGRAMME,
  [NewProductUnit.KILOMETER]: ProductUnit.KILOMETRE,
  [NewProductUnit.LITER]: ProductUnit.LITRE,
  [NewProductUnit.BATCH]: ProductUnit.LOT,
  [NewProductUnit.METER]: ProductUnit.METRE,
  [NewProductUnit.SQUARE_METER]: ProductUnit.METRE_CARRE,
  [NewProductUnit.CUBIC_METER]: ProductUnit.METRE_CUBE,
  [NewProductUnit.LINEAR_METER]: ProductUnit.METRE_LINEAIRE,
  [NewProductUnit.PERSON]: ProductUnit.PERSONNE,
  [NewProductUnit.TON]: ProductUnit.TONNE,
  [NewProductUnit.WORD]: ProductUnit.MOT,
  [NewProductUnit.PAGE]: ProductUnit.PAGE,
  [NewProductUnit.LEAFLET]: ProductUnit.FEUILLET,
  [NewProductUnit.PARAGRAPH]: ProductUnit.PARAGRAPHE,
  [NewProductUnit.MINUTE]: ProductUnit.MINUTE,
};
