import type { CreateRating, ReadSummaryRating } from '@abby/core-legacy'
import { Resource } from '../Resource'

export class Rating extends Resource {
  create (payload: CreateRating): Promise<ReadSummaryRating> {
    return this.axios.$post('/rating', payload)
  }

  getSummary (source: string): Promise<ReadSummaryRating> {
    return this.axios.$get(`/rating/summary/${source}`)
  }
}
