export enum GuideLevelItem {
  MEET_ABBY = 'meet_abby',
  BILL_YOUR_CUSTOMERS_QUICKLY = 'bill_your_customers_quickly',
  DO_YOUR_ACCOUNTING_EASILY = 'do_your_accounting_easily',
  GET_ORGANIZE_EASILY = 'get_organize_easily',
}

export enum GuideStepItem {
  MEET_ABBY = 'meet_abby',
  CUSTOM_YOUR_THEME = 'custom_your_theme',
  CONFIGURE_YOUR_NUMBERING_FORMAT = 'configure_your_numbering_format',
  CONFIGURE_YOUR_BILLING_COUNTER = 'configure_your_billing_counter',
  CREATE_YOUR_FIRST_ESTIMATE_OR_PURCHASE_ORDER = 'create_your_first_estimate_or_purchase_order',
  CREATE_YOUR_FIRST_ADVANCE = 'create_your_first_advance',
  CREATE_YOUR_FIRST_FINAL_INVOICE_STILL_TO_BE_PAID = 'create_your_first_final_invoice_still_to_be_paid',
  CREATE_YOUR_FIRST_INVOICE = 'create_your_first_invoice',
  SAVE_TIME_BY_CONFIGURING_DEFAULT_INFORMATION = 'save_time_by_configuring_default_information',
  ACTIVATE_ONLINE_PAYMENT_OF_YOUR_INVOICE = 'activate_online_payment_of_your_invoice',
  ACTIVATE_URSSAF_IMMEDIATE_ADVANCE = 'activate_urssaf_immediate_advance',
  IMPORT_YOUR_CLIENTS_AND_INVOICES = 'import_your_clients_and_invoices',
  DISABLE_TEST_MODE = 'disable_test_mode',
  CONFIGURE_YOUR_CONTRIBUTION_RATES = 'configure_your_contribution_rates',
  CONFIGURE_YOUR_VAT = 'configure_your_vat',
  ESTIMATE_YOUR_SOCIAL_SECURITY_CONTRIBUTIONS = 'estimate_your_social_security_contributions',
  CONNECT_YOUR_BANK_ACCOUNT = 'connect_your_bank_account',
  ANNOTATE_YOUR_FIRST_TRANSACTION = 'annotate_your_first_transaction',
  ADD_AN_ENTRY_TO_YOUR_PURCHASES_BOOK = 'add_an_entry_to_your_purchases_book',
  CONNECT_YOUR_URSSAF_ACCOUNT = 'connect_your_urssaf_account',
  CREATE_YOUR_FIRST_TASK = 'create_your_first_task',
  CREATE_CARD_IN_THE_TRACKING_TABLE = 'create_card_in_the_tracking_table',
  CREATE_YOUR_FIRST_TIME_TRACKING = 'create_your_first_time_tracking',
}

export const GuideConfig = {
  [GuideLevelItem.MEET_ABBY]: [
    GuideStepItem.MEET_ABBY,
  ],
  [GuideLevelItem.BILL_YOUR_CUSTOMERS_QUICKLY]: [
    GuideStepItem.CUSTOM_YOUR_THEME,
    GuideStepItem.CONFIGURE_YOUR_NUMBERING_FORMAT,
    GuideStepItem.CONFIGURE_YOUR_BILLING_COUNTER,
    GuideStepItem.CREATE_YOUR_FIRST_ESTIMATE_OR_PURCHASE_ORDER,
    GuideStepItem.CREATE_YOUR_FIRST_ADVANCE,
    GuideStepItem.CREATE_YOUR_FIRST_FINAL_INVOICE_STILL_TO_BE_PAID,
    GuideStepItem.CREATE_YOUR_FIRST_INVOICE,
    GuideStepItem.SAVE_TIME_BY_CONFIGURING_DEFAULT_INFORMATION,
    GuideStepItem.ACTIVATE_ONLINE_PAYMENT_OF_YOUR_INVOICE,
    GuideStepItem.ACTIVATE_URSSAF_IMMEDIATE_ADVANCE,
    GuideStepItem.IMPORT_YOUR_CLIENTS_AND_INVOICES,
    GuideStepItem.DISABLE_TEST_MODE,
  ],
  [GuideLevelItem.DO_YOUR_ACCOUNTING_EASILY]: [
    GuideStepItem.CONFIGURE_YOUR_CONTRIBUTION_RATES,
    GuideStepItem.CONFIGURE_YOUR_VAT,
    GuideStepItem.ESTIMATE_YOUR_SOCIAL_SECURITY_CONTRIBUTIONS,
    GuideStepItem.CONNECT_YOUR_BANK_ACCOUNT,
    GuideStepItem.ANNOTATE_YOUR_FIRST_TRANSACTION,
    GuideStepItem.ADD_AN_ENTRY_TO_YOUR_PURCHASES_BOOK,
    GuideStepItem.CONNECT_YOUR_URSSAF_ACCOUNT,
  ],
  [GuideLevelItem.GET_ORGANIZE_EASILY]: [
    GuideStepItem.CREATE_YOUR_FIRST_TASK,
    GuideStepItem.CREATE_CARD_IN_THE_TRACKING_TABLE,
    GuideStepItem.CREATE_YOUR_FIRST_TIME_TRACKING,
  ],
};
