import { ActionTree, GetterTree, MutationTree } from 'vuex'
import { IssueStatus } from '@abby/core-legacy'
import type { CreateIssue, GitlabLabel, QueryIssues, ReadIssue, UpdateIssue } from '@abby/core-legacy'
import { RootState } from '~/store/index'

type Pagination = { totalItems: number, loading: boolean, page: number, limit: number }

export interface IssueState {
  issues: {
    [K: string]: ReadIssue[]
  },
  pagination: {
    [K: string]: Pagination
  },
  search: string,
  labels: number[],
  labelsList: GitlabLabel[]
  currentIssue: ReadIssue | null,
  queryLoading: boolean,
  issueModalOpened: boolean,
}

export const state = (): IssueState => ({
  issues: {},
  pagination: {},
  search: '',
  labels: [],
  labelsList: [],
  currentIssue: null,
  queryLoading: false,
  issueModalOpened: false,
})

export const getters: GetterTree<IssueState, RootState> = {
  issues: state => state.issues,
  pagination: state => state.pagination,
  search: state => state.search,
  labels: state => state.labels,
  labelsList: state => state.labelsList,
  currentIssue: state => state.currentIssue,
  queryLoading: state => state.queryLoading,
  issueModalOpened: state => state.issueModalOpened,
}

export const mutations: MutationTree<IssueState> = {
  SET_ISSUES (state, payload: { status: IssueStatus, data: ReadIssue[] }) {
    state.issues = {
      ...state.issues,
      [payload.status]: payload.data,
    }
  },
  REMOVE_ISSUE (state, payload: { status: IssueStatus, index: number }) {
    state.issues[payload.status].splice(payload.index, 1)
  },
  SET_PAGINATION (state, value: Pagination & { status: IssueStatus }) {
    const { status, ...data } = value
    state.pagination = {
      ...state.pagination,
      [status]: data,
    }
  },
  SET_LABELS_LIST (state, list: GitlabLabel[]) {
    state.labelsList = list
  },
  SET_CURRENT_ISSUE (state, currentIssue: ReadIssue | null) {
    state.currentIssue = currentIssue
  },
  SET_QUERY_LOADING (state, value: boolean) {
    state.queryLoading = value
  },
  SET_ISSUE_MODAL_OPENED (state, issueModalOpened: boolean) {
    state.issueModalOpened = issueModalOpened
  },
  RESET (_currentState) {
    const newState = state()
    _currentState = Object.assign(_currentState, newState)
  },
}

export const actions: ActionTree<IssueState, IssueState> = {
  async fetchIssues ({ commit, getters }, payload: QueryIssues & { truncate?: boolean }) {
    if (!payload.status) { return }

    if (payload?.truncate) {
      commit('SET_ISSUES', {
        status: payload.status,
        data: [],
      })
    }

    const results = await this.$api.issue.getIssues({
      page: payload.page,
      limit: payload.limit,
      status: payload.status,
      ...(getters.search.length
        ? {
          search: getters.search,
        }
        : {}),
      ...(getters.labels.length
        ? {
          range: getters.labels,
        }
        : {}),
    })

    commit('SET_ISSUES', {
      status: payload.status,
      data: [
        ...(getters.issues?.[payload.status] || []),
        ...results.data,
      ],
    })
    commit('SET_PAGINATION', {
      status: payload.status,
      totalItems: results.totalItems,
      page: payload.page,
      limit: payload.limit,
    })
  },
  async createIssue ({ commit, getters }, payload: CreateIssue) {
    const result = await this.$api.issue.createIssue(payload)
    this.$busManager.emit('issueCreated', result)
    const data = [
      result,
      ...getters.issues?.[result?.status] ? getters.issues[result.status] : [],
    ]

    commit('SET_PAGINATION', {
      status: payload.status,
      totalItems: getters.pagination[payload.status].totalItems + 1,
      page: getters.pagination[payload.status].page,
      limit: getters.pagination[payload.status].limit,
    })
    commit('SET_ISSUES', {
      status: result.status,
      data,
    })
  },
  async updateIssue ({ commit, getters }, payload: UpdateIssue) {
    const result = await this.$api.issue.updateIssue(payload)
    this.$busManager.emit('issueUpdated', result)
    const data = getters
      .issues[result.status]
      .map((issue: ReadIssue) => issue.id === result.id ? result : issue)

    commit('SET_PAGINATION', {
      status: payload.status,
      totalItems: getters.pagination[payload.status].totalItems,
      page: getters.pagination[payload.status].page,
      limit: getters.pagination[payload.status].limit,
    })
    commit('SET_ISSUES', {
      status: result.status,
      data,
    })
  },
  async deleteIssue ({ commit, getters }, payload: ReadIssue) {
    await this.$api.issue.deleteIssue(payload.id)
    const index = getters.issues[payload.status]?.findIndex((i: ReadIssue) => i.id === payload.id)
    commit('REMOVE_ISSUE', {
      status: payload.status,
      index,
    })
    commit('SET_PAGINATION', {
      status: payload.status,
      totalItems: getters.pagination[payload.status].totalItems - 1,
      page: getters.pagination[payload.status].page,
      limit: getters.pagination[payload.status].limit,
    })
  },
  async fetchLabelsList ({ commit }) {
    const results = await this.$api.issue.getLabelsList()
    commit('SET_LABELS_LIST', results)
  },
  setPagination ({ commit }, value: { totalItems: number, status: IssueStatus }) {
    commit('SET_PAGINATION', value)
  },
  setIssues ({ commit }, payload: { status: IssueStatus, data: ReadIssue[] }) {
    commit('SET_ISSUES', payload)
  },
  setCurrentIssue ({ commit }, currentIssue: ReadIssue | null) {
    commit('SET_CURRENT_ISSUE', currentIssue)
  },
  setIssueModalOpened ({ commit }, issueModalOpened: boolean) {
    commit('SET_ISSUE_MODAL_OPENED', issueModalOpened)
  },
  reset ({ commit }) {
    commit('RESET')
  },
}
