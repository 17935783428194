export enum StripeProductType {
  ABBY_PLUS = 'abby_plus',
  ACRE = 'acre',
  CFE = 'cfe',
  ABBY_FREE = 'abby_free',
  ABBY_START = 'abby_start',
  ABBY_CREATION_START = 'abby_creation_start',
  ABBY_CREATION_START_PREMIUM = 'abby_creation_start_premium',
  ABBY_PRO = 'abby_pro',
  ABBY_BUSINESS = 'abby_business',
  ABBY_CE = 'abby_ce',
  MEETING_WITH_AN_EXPERT = 'meeting_with_an_expert',
  FORMATION_CLIENT = 'formation_client',
  FORMATION_COMMUNIQUER = 'formation_communiquer',
  FORMATION_CLIENT_COMMUNIQUER_DISCOUNT = 'formation_client_communiquer_discount',
  BILLING_TEMPLATE_2 = 'billing_template_2',
  BILLING_TEMPLATE_3 = 'billing_template_3',
  ABBY_ACADEMIE_MARKETING_COMMUNICATION = 'abby_academie_marketing_communication',
  ABBY_ACADEMIE_CREATION = 'abby_academie_creation',
  ABBY_CONTACT = 'abby_contact',
}

/**
 *
 * @remarks permet de retourner la valeur du produit pour le tracking Facebook notamment
 */
export function getProductValue(product: StripeProductType): number {
  const value = {
    [StripeProductType.ABBY_PLUS]: 19.99,
    [StripeProductType.ABBY_PRO]: 9.99,
    [StripeProductType.ABBY_CE]: 5,
    [StripeProductType.ACRE]: 4.99,
    [StripeProductType.CFE]: 4.99,
    [StripeProductType.MEETING_WITH_AN_EXPERT]: 34.99,
    [StripeProductType.FORMATION_CLIENT]: 9.99,
    [StripeProductType.FORMATION_COMMUNIQUER]: 9.99,
    [StripeProductType.FORMATION_CLIENT_COMMUNIQUER_DISCOUNT]: 19.00,
    [StripeProductType.BILLING_TEMPLATE_2]: 9.99,
    [StripeProductType.BILLING_TEMPLATE_3]: 9.99,
    [StripeProductType.ABBY_ACADEMIE_MARKETING_COMMUNICATION]: 9.99,
    [StripeProductType.ABBY_ACADEMIE_CREATION]: 14.99,
    [StripeProductType.ABBY_CONTACT]: 49.99,
  };

  // @ts-ignore
  return value[product] || 0;
}

export function getProductName(product?: StripeProductType): string {
  if (!product) {
    return 'Upsell inconnu';
  }
  const value = {
    [StripeProductType.ABBY_PLUS]: 'Abby Plus',
    [StripeProductType.ACRE]: 'ACRE',
    [StripeProductType.CFE]: 'CFE',
    [StripeProductType.ABBY_FREE]: 'Abby Basique',
    [StripeProductType.ABBY_START]: 'Abby Start',
    [StripeProductType.ABBY_PRO]: 'Abby Pro',
    [StripeProductType.ABBY_CE]: 'Abby CE',
    [StripeProductType.ABBY_CREATION_START]: 'Abby Création Starter',
    [StripeProductType.ABBY_CREATION_START_PREMIUM]: 'Abby Création Premium',
    [StripeProductType.ABBY_BUSINESS]: 'Abby Business',
    [StripeProductType.MEETING_WITH_AN_EXPERT]: 'Appel avec un expert',
    [StripeProductType.FORMATION_CLIENT]: 'Formation client',
    [StripeProductType.FORMATION_COMMUNIQUER]: 'Formation communiquer',
    [StripeProductType.FORMATION_CLIENT_COMMUNIQUER_DISCOUNT]: 'Formation communiquer discount',
    [StripeProductType.BILLING_TEMPLATE_2]: 'Template de facturation 2',
    [StripeProductType.BILLING_TEMPLATE_3]: 'Template de facturation 3',
    [StripeProductType.ABBY_ACADEMIE_MARKETING_COMMUNICATION]: 'Formation marketing / communication',
    [StripeProductType.ABBY_ACADEMIE_CREATION]: 'Formation création',
    [StripeProductType.ABBY_CONTACT]: 'Abby Contact',
  };

  return value[product];
}
